import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosClient } from "../../utils/axiosClient";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const AddProductsPrice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedItems } = location.state || [];
  // Store prices for each variant inside the respective product
  const [productsPrice, setProductsPrice] = useState({});
  const myProfile = useSelector((state) => state?.appConfigReducer?.myProfile);
  const shopId = myProfile.shopId;

  // Handle price change for a specific variant under a product
  const handlePriceChange = (productId, unit, price) => {
    setProductsPrice((prev) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        [unit]: price,
      },
    }));
  };

  // Submit prices for all products and their variants
  const handleSubmit = async () => {
    const formattedData = Object.entries(productsPrice).map(
      ([productId, variants]) => ({
        productId,
        variants: Object.entries(variants).map(([unit, price]) => ({
          unit,
          price: Number(price),
        })),
      })
    );

    try {
      const res = await axiosClient.post(`shop/products/${shopId}`, {
        products: formattedData,
      });
      toast.success(res.result);
      navigate(`/admin/${shopId}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-4 min-h-screen bg-gray-50 -mt-2">
      <p className="sm:text-xl text-center text-gray-700 font-medium mb-4">
        Price Your Products
      </p>

      {/* Products Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {selectedItems?.map((item) => (
          <div
            key={item.id}
            className="bg-white border border-gray-200 rounded-lg shadow-md p-4 text-center"
          >
            {/* Product Image */}
            <img
              src={item.img}
              alt={item.name}
              className="w-28 h-28 mx-auto rounded-lg object-cover"
            />
            {/* Product Name */}
            <h3 className="mt-3 text-lg font-semibold text-gray-700">
              {item.name}
            </h3>

            {/* Product Variants */}
            <div className="mt-4 space-y-2">
              {item.variants.map((variant) => (
                <div key={variant._id} className="flex items-center gap-3">
                  <span className="w-16 text-gray-600 font-medium">
                    {variant.unit}
                  </span>
                  <input
                    type="number"
                    placeholder="Price (₹)"
                    className="flex-1 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-green-500"
                    value={productsPrice[item.id]?.[variant.unit] || ""}
                    onChange={(e) =>
                      handlePriceChange(item.id, variant.unit, e.target.value)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Submit Button */}
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 w-full max-w-sm">
        <button
          onClick={handleSubmit}
          className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-3 rounded-lg shadow-md transition-all"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddProductsPrice;
