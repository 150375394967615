import React, { useEffect, useState } from "react";
import heroImg from "../assets/StockCake-Surf.jpg";
import AllShops from "./AllShops";
import firstImage from "../assets/FirstImage.jpeg";
import secondImage from "../assets/SecondImage.jpg";
import thirdImage from "../assets/ThirdImage.jpeg";
import { getItem, KEY_ACCESS_TOKEN } from "../utils/localStorageManager";
import { Link, useNavigate } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { regSw, subscribe } from "../helper";
import { useSelector } from "react-redux";
import BarberCategory from "../assets/BarberCategory.jpg";
import GroceryCategory from "../assets/GroceryCategory.jpg";
import ServiceCard from "./ServiceCard";
import DiscountCard from "./DiscountCart";

function Hero() {
  const user = getItem(KEY_ACCESS_TOKEN);
  const navigate = useNavigate();
  const [idx, setIdx] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const myProfile = useSelector((state) => state?.appConfigReducer?.myProfile);

  const cards = [
    {
      img: firstImage,
      title: "Skip the Wait",
      content: "Book Appointments",
    },
    {
      img: secondImage,
      title: "Shop Locally",
      content: "Shop Nearby",
    },
    {
      img: thirdImage,
      title: "Timely Services",
      content: "Get Services on Time",
    },
  ];

  const servicesArray = [
    {
      _id: "672055eb64db0cb7bc050859",
      title: "Book Appo",
      description: "Schedule your nearby salon appointment.",
      button: "Book Now",
      img: BarberCategory,
      hex: "rgba(0, 0, 0, 0.6)",
      path: "/barber-category",
    },
    {
      _id: "6720550c64db0cb7bc050856",
      title: "Trend Mart",
      description: "Your nearby Grocery stores at your hand",
      button: "Shop Now",
      img: GroceryCategory,
      hex: "rgba(0, 0, 0, 0.6)",
      path: "/grocery-category",
    },
    {
      _id: "6720550c64db0cb7bc05435",
      title: "Pick & Drop",
      description: "Your nearby city rikshaws at your hand",
      button: "Book Now",
      img: GroceryCategory,
      hex: "rgba(0, 0, 0, 0.6)",
      path: "/grocery-category",
    },
  ];

  useEffect(() => {
    checkSubscription();
  }, []);

  const checkSubscription = async () => {
    const serviceWorkerReg = await regSw();

    let subscription = await serviceWorkerReg.pushManager.getSubscription();

    if ((user && subscription === null) || myProfile?.pushSubscription === "") {
      registerAndSubscribe();
    }
  };

  async function registerAndSubscribe() {
    try {
      const serviceWorkerReg = await regSw();
      await subscribe(serviceWorkerReg);
    } catch (e) {
      return;
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleChange((idx + 1) % cards.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [idx]);

  const handleChange = (newIdx) => {
    if (newIdx !== idx) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 200);
    }
    setIdx(newIdx);
  };

  return (
    <>
      {/* <div className="relative h-screen overflow-hidden">
        <div
          style={{
            backgroundImage: `url(${heroImg})`,
            height: "100vh",
          }}
          className="fixed top-0 left-0 w-full h-full bg-cover bg-center -z-10"
        ></div>
        <div className="absolute inset-0 flex flex-col gap-20 items-center justify-center text-center text-white z-10">
          <div>
            <p className="text-5xl lg:text-7xl">Book & Buy</p>
            <p className="text-lg lg:text-2xl mt-2">Your Time Matters</p>
          </div>
          <div className="flex gap-10 items-center">
            <button
              className="text-xs sm:text-sm md:text-base border border-white py-1 px-2 sm:px-3 md:px-4 lg:px-5 rounded hover:opacity-90 hover:bg-blue-900"
              onClick={() => navigate("/allShops/1")}
            >
              Book Now
            </button>
            <button
              className="text-xs sm:text-sm md:text-base border border-white py-1 px-2 sm:px-3 md:px-4 lg:px-5 rounded hover:opacity-90 hover:bg-blue-900"
              onClick={() => navigate("/allShops/0")}
            >
              Buy Now
            </button>
          </div>
        </div>
      </div> */}

      <div className="flex justify-center mb-4 mx-4">
        <DiscountCard onClick={() => navigate("/barber-category")} />
      </div>

      <div className="bg-gray-100 ">
        <p className="text-white bg-blue-900 py-1 text-center text-lg sm:text-2xl font-semibold">
          Streamline Your Day with Book & Buy
        </p>

        <div className="md:hidden">
          <div
            className={`p-3 rounded-lg flex flex-col items-center transition-opacity duration-500 ${
              isVisible ? "opacity-0" : "opacity-100"
            }`}
            key={idx}
          >
            <img src={cards[idx].img} alt="" className="size-44 rounded-lg" />
            <p className="font-semibold">{cards[idx].title}</p>
            <p className="font-medium text-xs">{cards[idx].content}</p>
          </div>

          <div className="flex items-center gap-1 justify-center text-2xl">
            {cards.map((_, indx) => (
              <p key={indx} onClick={() => handleChange(indx)}>
                <BsDot
                  className={`cursor-pointer ${idx == indx && "text-blue-500"}`}
                />
              </p>
            ))}
          </div>
        </div>

        <div className="hidden md:grid sm:grid-cols-1 md:grid-cols-3 gap-10 md:gap-14 lg:gap-16 text-sm p-10">
          {cards.map((data, idx) => (
            <div key={idx} className=" bg-white p-3 rounded-lg text-center">
              <img src={data.img} alt={data.title} className="w-full h-64" />
              <p className="font-semibold">{data.title}</p>
              <p className="font-light">{data.content}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
        {servicesArray.map((data) => (
          <ServiceCard service={data} key={data._id} />
        ))}
      </div>

      {!user && (
        <div className="text-xs sm:text-sm bg-gray-100 text-center py-10">
          <p>
            New here?{" "}
            <Link to="/signup" className="text-blue-900 underline">
              Sign up
            </Link>{" "}
            to explore more!
          </p>
          <p>Already have an account?</p>
          <p>
            <Link to="/login" className="text-blue-900 underline">
              Log in
            </Link>{" "}
            to continue discovering everything our site has to offer.
          </p>
        </div>
      )}
      {/* {user && <AllShops />} */}
    </>
  );
}

export default Hero;
