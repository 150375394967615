import toast from "react-hot-toast";
import { axiosClient } from "./axiosClient";

const initPay = (service, data, id) => {
  const route =
    service === "order"
      ? `payment/verify/${id}`
      : `payment/verifyAppointmentPayment/${id}`;

  return new Promise((resolve, reject) => {
    const { id, amount, currency } = data;

    const options = {
      key: "rzp_test_O8dTUyd5P0J4XU",
      amount: amount,
      currency: currency,
      name: "Book And Buy",
      description: "Service Payment",
      image: "",
      order_id: id,
      handler: async (response) => {
        try {
          const verificationResponse = await axiosClient.post(route, {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            amount: amount / 100,
          });
          if (verificationResponse.status === "ok") {
            toast.success("Payment Successful");
            resolve(true);
          } else {
            toast.error("Payment Failed");
            resolve(false);
          }
        } catch (error) {
          toast.error("Payment Cancelled");
          resolve(false);
        }
      },
      theme: { color: "#F37254" },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  });
};

export const handlePayment = async (totalPrice, id, service) => {
  const route =
    service === "order" ? "payment/orders" : "payment/createAppointmentOrder";

  try {
    const data = await axiosClient.post(route, {
      amount: totalPrice,
    });
    return await initPay(service, data.result, id);
  } catch (error) {
    return false;
  }
};
