import { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { AiOutlineSearch } from "react-icons/ai";

const SearchBar = ({
  productNames,
  iconColor,
  borderColor,
  searchQuery,
  setSearchQuery,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [product, setProduct] = useState(productNames[0]);
  const intervalRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    setProduct(productNames[0]);
    resetAnimation();
  }, [productNames]);

  useEffect(() => {
    if (!searchQuery) {
      startAnimation();
    } else {
      stopAnimation();
    }
    return () => stopAnimation();
  }, [searchQuery]);

  const startAnimation = () => {
    stopAnimation();
    intervalRef.current = setInterval(() => {
      controls
        .start({
          y: [20, 0, -20],
          opacity: [0, 1, 0],
          transition: { duration: 3, ease: "easeOut" },
        })
        .then(() => {
          setProduct(
            productNames[Math.floor(Math.random() * productNames.length)]
          );
        });
    }, 3000);
  };

  const stopAnimation = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const resetAnimation = () => {
    stopAnimation();
    startAnimation();
  };

  return (
    <div
      className={`flex items-center border rounded-lg px-3 py-2 relative bg-white transition-all duration-300 ${
        isFocused ? `border-[${borderColor}]` : "border-gray-300"
      }`}
    >
      <AiOutlineSearch size={18} className={`${iconColor}`} />
      <input
        type="text"
        className="flex-1 text-base pl-2 focus:outline-none"
        placeholder=""
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
         {!searchQuery && (
          <span className="absolute left-10 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">
            Search for
          </span>
        )}

        {/* Animated Product Placeholder */}
        {!searchQuery && (
          <motion.span
            className="absolute left-32 -translate-y-1/2 text-gray-500 pointer-events-none"
            animate={controls}
          >
            "{product}"
          </motion.span>
      )}
    </div>
  );
};

export default SearchBar;
