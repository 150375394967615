import React, { useEffect, useState } from "react";
import { axiosClient } from "../../utils/axiosClient";
import { AiOutlineArrowRight } from "react-icons/ai";
import Service from "../../components/Service";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ImCancelCircle } from "react-icons/im";
import { MdDeleteOutline } from "react-icons/md";
import ConfirmationModal from "../../components/ConfirmationModal";
import { getItem } from "../../utils/localStorageManager";

function UpdateShopFeatures() {
  const [serviceMode, setServiceMode] = useState(true);
  const [isFemale, setIsFemale] = useState(() => {
    return getItem("isFemale") === "true";
  });
  const [genderId, setGenderId] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [serviceName, setServiceName] = useState("");

  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [updatedPrices, setUpdatedPrices] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFemaleItems, setSelectedFemaleItems] = useState([]);

  const navigate = useNavigate();

  const shopData = useSelector((state) => state?.shopsSlice?.shopsData);
  const shopId = shopData._id;

  useEffect(() => {
    localStorage.setItem("isFemale", isFemale.toString());
    api();
  }, [isFemale]);

  const api = async () => {
    try {
      const resp = await axiosClient.get(
        `/shop/${shopId}/${isFemale ? "Female" : "Male"}`
      );
      setServices(resp.result.services);
      setGenderId(resp.result.genderId);
    } catch (error) {}
  };

  useEffect(() => {
    const allServices = async () => {
      try {
        const res = await axiosClient.get(
          `/allServices/${isFemale ? "Female" : "Male"}`
        );

        const filteredServices = res.result[0].services.filter(
          (service) =>
            !services.some(
              (shopService) => shopService.serviceId === service._id
            )
        );
        setFilteredServices(filteredServices);
      } catch (error) {}
    };

    allServices();
  }, [services, isFemale]);

  const handlePriceChange = (serviceId, newPrice) => {
    // Update the price locally
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.serviceId === serviceId
          ? { ...service, price: newPrice }
          : service
      )
    );

    // Track updated prices
    setUpdatedPrices((prevUpdates) => {
      const existingIndex = prevUpdates.findIndex(
        (item) => item.serviceId === serviceId
      );

      if (existingIndex !== -1) {
        const updated = [...prevUpdates];
        updated[existingIndex] = { serviceId, newPrice };
        return updated;
      } else {
        return [...prevUpdates, { serviceId, newPrice }];
      }
    });
  };

  const toggleSwitch = () => {
    setIsFemale((previousState) => !previousState);
    setGenderId("");
  };

  const toggleServiceModeSwitch = () => {
    setServiceMode((previousState) => !previousState);
    setSelectedItems([]);
    setSelectedFemaleItems([]);
  };

  const handleToggleSelection = (item) => {
    {
      isFemale &&
        setSelectedFemaleItems((prevItems) => {
          const alreadySelected = prevItems.find((i) => i._id === item._id);
          const updatedItems = alreadySelected
            ? prevItems.filter((i) => i._id !== item._id)
            : [...prevItems, item];

          return updatedItems;
        });
    }

    {
      !isFemale &&
        setSelectedItems((prevItems) => {
          const alreadySelected = prevItems.find((i) => i._id === item._id);
          const updatedItems = alreadySelected
            ? prevItems.filter((i) => i._id !== item._id)
            : [...prevItems, item];

          return updatedItems;
        });
    }
  };

  const handleRemoveItem = (id) => {
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item._id !== id)
    );
  };

  const handleRemoveFemaleItem = (id) => {
    setSelectedFemaleItems((prevItems) =>
      prevItems.filter((item) => item._id !== id)
    );
  };

  const submitPrices = async () => {
    const payload = {
      shopFeatureId: genderId,
      services: updatedPrices,
    };

    try {
      const response = await axiosClient.put("/updateShop/feature", payload);
      toast.success(response.result);
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : String(e);
      toast.error(errorMessage);
    }
  };

  const handleOpenModal = (serviceId, serviceName) => {
    setServiceId(serviceId);
    setServiceName(serviceName);
    return setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setServiceId("");
    setServiceName("");
  };

  const handleDeleteService = async () => {
    try {
      const res = await axiosClient.delete(
        `/deleteShop/services/${shopId}/${genderId}/${serviceId}`
      );
      toast.success(res.result);
      api();
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : String(e);
      toast.error(errorMessage);
    }
    handleCloseModal();
  };

  const handleNavigate = () => {
    const maleServices = selectedItems;
    const femaleServices = selectedFemaleItems;
    navigate("/shop-feature-price", {
      state: { maleServices, femaleServices },
    });
  };

  return (
    <div className="w-full flex flex-col items-center p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 text-center">
        Service Mode
      </h2>

      {/* SERVICE MODE */}
      <div className="mt-4 flex items-center space-x-3">
        <span
          className={`text-base md:text-lg font-semibold ${
            !serviceMode ? "text-[#1E90FF]" : "text-[#555]"
          }`}
        >
          Update Services
        </span>
        <button
          className={`w-14  h-7 h-6 flex items-center rounded-full p-1 transition duration-300 ${
            serviceMode ? "bg-[#81b0ff]" : "bg-[#81b0ff]"
          }`}
          onClick={toggleServiceModeSwitch}
        >
          <div
            className={`h-5 w-5 bg-white rounded-full shadow-md transform ${
              serviceMode ? "translate-x-7" : "tanslate-x-0"
            } ${
              serviceMode ? "bg-[#1E90FF]" : "bg-[#1E90FF]"
            } transition duration-300`}
          ></div>
        </button>
        <span
          className={`text-base md:text-lg font-semibold ${
            serviceMode ? "text-[#1E90FF]" : "text-[#555]"
          }`}
        >
          Add Services
        </span>
      </div>

      {/* TOGGLE GENDER */}
      <div className="mt-4 flex items-center space-x-3">
        <span
          className={`text-base md:text-lg font-semibold ${
            !isFemale ? "text-[#1E90FF]" : "text-[#555]"
          }`}
        >
          Male
        </span>
        <button
          className={`w-14 h-7 flex items-center rounded-full p-1 transition duration-300 ${
            isFemale ? "bg-[#FFC1E3]" : "bg-[#81b0ff]"
          }`}
          onClick={toggleSwitch}
        >
          <div
            className={`h-5 w-5 bg-white rounded-full shadow-md transform ${
              isFemale ? "translate-x-7" : "translate-x-0"
            } ${
              isFemale ? "bg-[#FF1493]" : "bg-[#1E90FF]"
            } transition duration-300`}
          ></div>
        </button>
        <span
          className={`text-base md:text-lg font-semibold ${
            isFemale ? "text-[#FF1493]" : "text-[#555]"
          }`}
        >
          Female
        </span>
      </div>

      <p>{serviceMode ? "Add Service" : "Update Price"}</p>

      {!serviceMode && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
          {services.map((item) => (
            <div
              key={item._id}
              className="p-4 border rounded-lg shadow-md flex flex-col items-center bg-white"
            >
              {/* Service Image */}
              <div className="relative w-full h-40">
                <img
                  src={item.imgUrl}
                  alt={item.serviceTitle}
                  className={`rounded-md w-full h-40 ${
                    isFemale ? "border-pink-500" : "border-blue-500"
                  }`}
                />
              </div>

              {/* Service Title & Delete Button */}
              <button
                className="flex justify-between items-center w-full mt-2 p-2 bg-gray-100 rounded-md"
                onClick={() => handleOpenModal(item._id, item.serviceTitle)}
              >
                <span className="truncate">{item.serviceTitle}</span>
                <MdDeleteOutline size={20} className="text-black" />
              </button>

              {/* Price Input */}
              <input
                type="number"
                className="mt-2 p-2 w-full border rounded-md text-center focus:ring-2 focus:ring-blue-500"
                value={item.price}
                placeholder="₹"
                onChange={(e) =>
                  handlePriceChange(item.serviceId, e.target.value)
                }
              />
            </div>
          ))}
        </div>
      )}

      {/* SELECTED MALE ITEMS LIST */}
      {selectedItems?.length > 0 && !isFemale && (
        <div className="border-b border-gray-300 flex justify-center">
          <div>
            <h3 className="font-medium text-sm my-2 text-center">
              Selected Male Services
            </h3>

            <div className="flex md:justify-center space-x-4 w-96 overflow-x-auto hideScrollbar">
              {selectedItems.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="flex flex-col items-center snap-center"
                  >
                    <div className="relative">
                      <img
                        src={item.imgUrl.url}
                        alt={item.title}
                        className="rounded-md w-20 h-20 min-w-[5rem] object-cover"
                      />
                      <button
                        onClick={() => handleRemoveItem(item._id)}
                        className={`absolute top-1 right-1 rounded-full p-1 z-10 text-white 
                    ${
                      isFemale ? "bg-pink-500" : "bg-blue-500"
                    } hover:scale-110 transition`}
                      >
                        <ImCancelCircle size={14} />
                      </button>
                    </div>
                    <p className="mt-1 text-xs text-center">{item.title}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* SELECTED FEMALE ITEMS LIST */}
      {selectedFemaleItems?.length > 0 && isFemale && (
        <div className="border-b border-gray-300 flex justify-center">
          <div>
            <h3 className="font-medium text-sm my-2 text-center">
              Selected Female Services
            </h3>

            <div className="flex md:justify-center space-x-4 w-96 overflow-x-auto hideScrollbar">
              {selectedFemaleItems.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="flex flex-col items-center snap-center"
                  >
                    <div className="relative">
                      <img
                        src={item.imgUrl.url}
                        alt={item.title}
                        className="rounded-md w-20 h-20 min-w-[5rem] object-cover"
                      />
                      <button
                        onClick={() => handleRemoveFemaleItem(item._id)}
                        className={`absolute top-1 right-1 rounded-full p-1 z-10 text-white 
                    ${
                      isFemale ? "bg-pink-500" : "bg-blue-500"
                    } hover:scale-110 transition`}
                      >
                        <ImCancelCircle size={14} />
                      </button>
                    </div>
                    <p className="mt-1 text-xs text-center">{item.title}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* RENDERING SERVICES */}
      {serviceMode && filteredServices.length !== 0 ? (
        <Service
          services={filteredServices}
          selectedItems={isFemale ? selectedFemaleItems : selectedItems}
          onToggleSelection={handleToggleSelection}
          isFemale={isFemale}
        />
      ) : (
        <div
          className={`${!serviceMode && "hidden"}  ${
            serviceMode && "flex justify-center"
          } `}
        >
          <div className="p-5 rounded w-96">
            <p className="text-lg text-center font-semibold text-[#FF6347] mb-5">
              No New Services are available
            </p>
            <p className="text-[#333] text-center mb-10">
              All selected services have already been added to your shop
              services. No new services were added.
            </p>
          </div>
        </div>
      )}

      <button
        className={`flex items-center justify-center border rounded-md px-4 py-2 text-lg font-medium w-fit mx-auto mb-3 transition-colors
        ${
          isFemale
            ? "text-pink-500 border-pink-500"
            : "text-blue-500 border-blue-500"
        }
      `}
        onClick={serviceMode ? handleNavigate : submitPrices}
      >
        {serviceMode ? "Add Service" : "Update Price"}
        <AiOutlineArrowRight
          size={18}
          className={`ml-2 ${isFemale ? "text-[#FF1493]" : "text-[#1E90FF]"}`}
        />
      </button>

      <ConfirmationModal
        show={modalVisible}
        onClose={handleCloseModal}
        onConfirm={handleDeleteService}
        message={`Your're about to delete ${serviceName}`}
        appointmentAction="No"
        action="Yes"
      />
    </div>
  );
}

export default UpdateShopFeatures;
