import React, { useEffect, useState } from "react";
import SearchBar from "../components/globalComponents/SearchBar";
import CategorySection from "../components/grocyGoComponents/CategorySection";
import Seasonal from "../components/grocyGoComponents/Seasonal";
import { axiosClient } from "../utils/axiosClient";
import ProductCard from "../components/grocyGoComponents/ProductCard";
import { getLocation } from "../utils/getLocation";
import toast from "react-hot-toast";

const productNames = [
  "Oils",
  "Snacks",
  "Spices",
  "Vegetables",
  "Dairy",
  "Atta",
  "Rice",
  "Dal",
];

const GroceryCategory = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchProducts, setSearchProducts] = useState([]);

  useEffect(() => {
    const fetchSearchProduct = async () => {
      const location = await getLocation();
      if (!location) return;
      try {
        const response = await axiosClient.get(
          `grocery/search?keyword=${searchQuery}&latitude=${location.latitude}&longitude=${location.longitude}`
        );
        setSearchProducts(response.result);
      } catch (e) {
        toast.error(e);
      }
    };
    if (searchQuery.trim().length !== 0) {
      const delayDebounceFn = setTimeout(() => {
        fetchSearchProduct();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else if (searchQuery.length === 0) {
      setSearchProducts([]);
    }
  }, [searchQuery]);

  return (
    <div className="flex justify-center min-h-screen m-5 ">
      <div className="flex flex-col items-center">
        <SearchBar
          productNames={productNames}
          iconColor="text-green-500"
          borderColor="#81b0ff"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />

        {searchQuery.length !== 0 && (
          <div>
            <p className="my-5 text-[#2f2f2f] font-medium text-lg">
              Search Products
            </p>
            <ProductCard products={searchProducts} />
          </div>
        )}

        {searchQuery.length === 0 && searchProducts.length === 0 && (
          <>
            <p className="text-[#2f2f2f] mt-4 font-semibold text-lg">
              Categories
            </p>
            <CategorySection />

            <div className="relative text-center mb-5">
              <p className="text-[#2f2f2f] mt-4 font-semibold text-lg inline-block">
                Season's Special
              </p>
              <svg
                className="absolute left-1/2 -translate-x-1/2 bottom-[-8px]"
                width="120"
                height="10"
                viewBox="0 0 120 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 5C40 15 80 -5 115 5"
                  stroke="#FF5733"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </div>

            <Seasonal />
          </>
        )}
      </div>
    </div>
  );
};

export default GroceryCategory;
