export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Get day, month, and year
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const month = date.toLocaleString("en-US", { month: "long" });

  // Function to add ordinal suffix to day
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 4th, 5th, etc.
    switch (day % 10) {
      case 1:
        return "st"; // 1st
      case 2:
        return "nd"; // 2nd
      case 3:
        return "rd"; // 3rd
      default:
        return "th";
    }
  };

  const ordinalSuffix = getOrdinalSuffix(day);
  return `${day}${ordinalSuffix} ${month}, ${year}`;
};

export const extractTimeFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};
