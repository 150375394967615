import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import CartItem from "./CartItem";
import { BsCartX } from "react-icons/bs";
import { useSelector } from "react-redux";
import ConfirmationModal from "../ConfirmationModal";
import { useNavigate } from "react-router-dom";
import BookOrder from "./BookOrder";

function Cart({ onClose }) {
  const [isClosing, setIsClosing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [toggleOrder, setToggleOrder] = useState(false);
  const navigate = useNavigate();

  const cart = useSelector((state) => state?.groceryCart?.cart);
  const shopId = useSelector((state) => state?.groceryCart?.shopId);
  const myProfile = useSelector((state) => state?.appConfigReducer?.myProfile);
  const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);
  const totalPrice = cart.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const isCartEmpty = cart.length === 0;

  const handleCloseClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 400); // Delay onClose to allow animation to finish
  };

  const handleCloseModal = () => {
    setShowModal(false);
    return;
  };

  const handleConfirm = () => {
    navigate("/updateProfile");
    handleCloseModal();
  };

  const handlePlaceOrder = async () => {
    if (myProfile === null) {
      navigate("/login");
      return;
    }

    setToggleOrder(true);
  };

  return (
    <>
      <div className={`fixed inset-0  flex justify-end z-50 `}>
        <div
          className={`fixed inset-0 bg-black transition-opacity duration-300 ${
            isClosing ? "opacity-0" : "opacity-50"
          }`}
          onClick={handleCloseClick}
        ></div>
        <div
          className={`relative h-screen w-60 sm:w-80 bg-white text-base lg:text-lg transition-transform duration-300 ${
            isClosing ? "animate-slideOut" : "animate-slideIn"
          }`}
        >
          <div className="h-14 flex items-center justify-between bg-[#607d8b] text-[#fff] p-2 font-medium">
            <h3>Shopping Cart</h3>
            <div className="cursor-pointer" onClick={handleCloseClick}>
              <AiOutlineClose />
            </div>
          </div>
          <div className="h-full overflow-y-scroll hideScrollbar">
            <div className="h-max text-sm sm:text-base lg:text-lg">
              {isCartEmpty && (
                <div className="flex flex-col items-center gap-2 m-5">
                  <BsCartX className="text-5xl" />
                  <h4 className="font-semibold">Cart is Empty</h4>
                </div>
              )}
              {!isCartEmpty && (
                <>
                  <div className="relative mb-36">
                    {cart.map((product, idx) => (
                      <CartItem item={product} key={idx} />
                    ))}
                  </div>

                  {cart.length > 0 && (
                    <div className="absolute bottom-0 left-0 right-0 p-4 bg-[#fff] text-xs font-medium">
                      <div className="flex justify-between mb-2">
                        <p>
                          Items:{" "}
                          <span className="text-sm font-semibold">
                            {totalItems}
                          </span>
                        </p>
                        <p>
                          Total: ₹{" "}
                          <span className="text-sm font-semibold">
                            {totalPrice}
                          </span>
                        </p>
                      </div>
                      <p
                        className="bg-[#28A745] text-white text-center rounded py-1 cursor-pointer"
                        onClick={handlePlaceOrder}
                      >
                        Proceed to Payment
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
            <ConfirmationModal
              show={showModal}
              onClose={handleCloseModal}
              onConfirm={handleConfirm}
              message="Please update your phone number!"
              action="Update"
            />
          </div>
        </div>
      </div>
      {toggleOrder && (
        <BookOrder
          onClose={() => setToggleOrder(false)}
          price={totalPrice}
          cart={cart}
          shopId={shopId}
        />
      )}
    </>
  );
}

export default Cart;
