import { useEffect, useState } from "react";
import { format } from "date-fns";
import { axiosClient } from "../../utils/axiosClient";
import { formatDate } from "../../utils/formatDate";
import { Link } from "react-router-dom";
import { FaRegCreditCard } from "react-icons/fa6";
import { MdMyLocation } from "react-icons/md";
import toast from "react-hot-toast";
import ConfirmationModal from "../../components/ConfirmationModal";
import { handlePayment } from "../../utils/handlePayment";
import RatingModal from "../../components/RatingModal";
import { getLocation } from "../../utils/getLocation";

const tabData = [
  { key: "awaitingConfirmation", title: "Awaiting Confirmation" },
  { key: "today", title: "Today" },
  { key: "upcoming", title: "Upcoming" },
  { key: "completed", title: "Completed" },
  { key: "cancelled", title: "Cancelled" },
];

const MyAppointments = () => {
  const [index, setIndex] = useState(0);
  const [appointments, setAppointments] = useState({
    awaitingConfirmation: [],
    today: [],
    upcoming: [],
    completed: [],
    cancelled: [],
  });
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [appointmentId, setAppointmentId] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [ratingModal, setRatingModal] = useState(false);
  const [shopId, setShopId] = useState("");

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const res = await axiosClient.get("/appointment/");
      const fetchedAppointments = res.result;
      const todayDate = format(new Date(), "yyyy-MM-dd");

      const categorizedAppointments = {
        awaitingConfirmation: [],
        today: [],
        upcoming: [],
        completed: [],
        cancelled: [],
      };

      fetchedAppointments.forEach((appointment) => {
        const appointmentDate = format(
          new Date(appointment.date),
          "yyyy-MM-dd"
        );
        const status = appointment.status || "";

        if (status === "Pending") {
          categorizedAppointments.awaitingConfirmation.push(appointment);
        } else if (status === "Completed") {
          categorizedAppointments.completed.push(appointment);
        } else if (status === "Cancelled") {
          categorizedAppointments.cancelled.push(appointment);
        } else if (appointmentDate === todayDate) {
          categorizedAppointments.today.push(appointment);
        } else if (new Date(appointmentDate) > new Date(todayDate)) {
          categorizedAppointments.upcoming.push(appointment);
        }
      });

      setAppointments(categorizedAppointments);
    } catch (e) {
      console.error("Error fetching appointments:", e);
    }
  };

  const onCancelAppointment = async () => {
    try {
      const res = await axiosClient.delete(`/appointment/${appointmentId}`);
      toast.success(res.result);
      fetchAppointments();
      handleCloseModal();
    } catch (error) {}
  };

  const handleOpenModal = (appointmentId) => {
    setAppointmentId(appointmentId);
    return setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setAppointmentId("");
  };

  const payAmount = async (totalPrice, appointmentId) => {
    const paymentSuccess = await handlePayment(
      totalPrice,
      appointmentId,
      "appointment"
    );
    if (paymentSuccess) {
      fetchAppointments();
    }
  };

  useEffect(() => {
    const location = async () => {
      const location = await getLocation();

      if (location) {
        setLocation({
          lat: location.latitude,
          lng: location.longitude,
        });
      }
    };

    location();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      {/* TABS */}
      <div className="flex md:justify-center space-x-4 overflow-x-auto p-2 text-xs sm:text-sm hideScrollbar">
        {tabData.map((tab, idx) => (
          <button
            key={tab.key}
            className={`px-4 py-2 whitespace-nowrap rounded-full border transition ${
              index === idx
                ? "bg-[#607d8b] text-white"
                : "border-[#607d8b] text-[#607d8b]"
            }`}
            onClick={() => setIndex(idx)}
          >
            {tab.title} ({appointments[tab.key].length})
          </button>
        ))}
      </div>

      {/* APPOINTMENTS CARD */}
      <div className="mt-4">
        {appointments[tabData[index].key].length === 0 ? (
          <p className="text-center text-[#777]">No Appointments</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {appointments[tabData[index].key].map((appointment) => {
              const totalPrice = appointment.selectedServices.reduce(
                (acc, service) => acc + Number(service.price),
                0
              );
              return (
                <div
                  key={appointment._id}
                  className="p-4 border border-[#ccc] bg-white rounded-lg"
                >
                  <div className="flex justify-between items-center">
                    <p
                      className={
                        appointment.status === "Pending" ||
                        appointment.status === "Cancelled"
                          ? "text-red-500"
                          : "text-green-500"
                      }
                    >
                      {appointment.status}
                    </p>
                    {(appointment.status === "Pending" ||
                      appointment.status === "Confirmed") && (
                      <button
                        className="text-red-500 border border-red-500 px-2 py-1 rounded"
                        onClick={() => handleOpenModal(appointment._id)}
                      >
                        CANCEL
                      </button>
                    )}
                  </div>
                  <div className="my-2 flex gap-8 text-xs">
                    <img
                      src={appointment.shopImg}
                      alt={appointment.shopName}
                      className="h-16 w-32 object-cover rounded"
                    />
                    <div>
                      <Link
                        to={`/shop/${appointment.shopId}`}
                        className="text-sm font-semibold text-[#81b0ff] underline"
                      >
                        {appointment.shopName}
                      </Link>
                      <p className="text-[#555]">
                        {formatDate(appointment.date)} -{appointment.fromTime}
                      </p>
                    </div>
                  </div>

                  {/* SERVICES */}
                  <p className="border-b border-dashed border-[#494949] text-[#494949]">
                    Services:{" "}
                  </p>
                  <div className="flex overflow-x-scroll hideScrollbar gap-4 mt-2">
                    {appointment.selectedServices.map((service) => (
                      <div
                        key={service._id}
                        className="flex flex-col items-center gap-2 p-5 border border-[#ccc] rounded-md bg-white"
                      >
                        <img
                          src={service.imgUrl}
                          alt={service.serviceTitle}
                          className="h-10 w-20 object-cover rounded"
                        />
                        <p className="text-xs text-center">
                          {service.serviceTitle}
                        </p>
                        <p className="text-xs">₹ {service.price}</p>
                      </div>
                    ))}
                  </div>

                  {/* LOCATION */}
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&origin=${location.lat},${location.lng}&destination=${appointment?.location?.coordinates[1]},${appointment?.location?.coordinates[0]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center gap-1 mt-2 underline text-[#81b0ff] font-semibold text-xs sm:text-sm cursor-pointer"
                  >
                    <MdMyLocation />
                    <span>Get Location</span>
                  </a>

                  {/* PAYMENT  */}
                  <div className="flex items-center justify-between mt-2">
                    <div className="flex items-center gap-2">
                      <p className="text-[#607d8b]">
                        <FaRegCreditCard />
                      </p>
                      <p
                        className={`${
                          appointment.paymentStatus === "Pending"
                            ? "text-red-500"
                            : "text-green-500"
                        } font-semibold`}
                      >
                        {appointment.paymentStatus}
                      </p>
                    </div>
                    {appointment.paymentStatus.trim().toLowerCase() !==
                      "completed" && (
                      <p
                        className="text-[#607d8b] font-semibold border border-[#607d8b] p-2 rounded cursor-pointer"
                        onClick={() => payAmount(totalPrice, appointment._id)}
                      >
                        Pay Now
                      </p>
                    )}
                    {appointment.paymentStatus.trim().toLowerCase() ===
                      "completed" &&
                      appointment.status === "Completed" && (
                        <p
                          className="text-[#607d8b] font-semibold border border-[#607d8b] p-2 rounded cursor-pointer"
                          onClick={() => {
                            setShopId(appointment.shopId);
                            setRatingModal(true);
                          }}
                        >
                          Rate Service
                        </p>
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* MODALS */}

      <ConfirmationModal
        show={modalVisible}
        onClose={handleCloseModal}
        onConfirm={onCancelAppointment}
        message="You want to cancel the appointment?"
        appointmentAction="No, keep it"
        action="Yes, cancel it"
      />

      {ratingModal && (
        <RatingModal onClose={() => setRatingModal(false)} shopId={shopId} />
      )}
    </div>
  );
};

export default MyAppointments;
