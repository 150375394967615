import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axiosClient";
import { getLocation } from "../../utils/getLocation";

// Initial state
const initialState = {
  cart: [],
  actualPrices: {},
  shopId: null,
};

// Async thunk for fetching actual prices based on location
export const getActualPrice = createAsyncThunk(
  "grocery/getActualPrice",
  async (_, { rejectWithValue }) => {
    try {
      const location = await getLocation();
      if (location) {
        const response = await axiosClient.get(
          `/grocery/actual-price?latitude=${location.latitude}&longitude=${location.longitude}`
        );

        const shopId = response?.result?.shopId || null;
        const products = response?.result?.products || [];

        return {
          shopId,
          variants: products.reduce((acc, product) => {
            acc[product.productId] = product.variants;
            return acc;
          }, {}),
        };
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Redux slice
const groceryCartSlice = createSlice({
  name: "groceryCartSlice",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const curProduct = action.payload;

      const index = state.cart.findIndex((item) => item._id === curProduct._id);

      if (index === -1) {
        state.cart.push({ ...curProduct });
      } else {
        state.cart[index].quantity += 1;
        state.cart[index].unit = curProduct.unit;
      }
    },
    removeFromCart: (state, action) => {
      const index = state.cart.findIndex((item) => item._id === action.payload);

      if (index === -1) return;

      if (state.cart[index].quantity === 1) {
        state.cart = state.cart.filter((item) => item._id !== action.payload);
      } else {
        state.cart[index].quantity -= 1;
      }
    },
    resetCart: (state) => {
      state.cart = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActualPrice.fulfilled, (state, action) => {
      if (action.payload) {
        const { shopId, variants } = action.payload;
        state.shopId = shopId;
        Object.entries(variants).forEach(([productId, productVariants]) => {
          state.actualPrices[productId] = productVariants;
        });

        state.cart = state.cart.map((cartItem) => {
          if (state.actualPrices[cartItem._id]) {
            const matchedVariant = state.actualPrices[cartItem._id].find(
              (variant) => variant.unit === cartItem.unit
            );

            if (matchedVariant) {
              return {
                ...cartItem,
                price: matchedVariant.price,
              };
            }
          }
          return cartItem;
        });
      }
    });
  },
});

export const { addToCart, removeFromCart, resetCart } =
  groceryCartSlice.actions;
export default groceryCartSlice.reducer;
