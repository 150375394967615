import React from "react";
import { addToCart, removeFromCart } from "../../redux/slices/groceryCartSlice";
import { useDispatch, useSelector } from "react-redux";

function CartButton({ item, selectedQuantities }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.groceryCart.cart);
  const added = cart.some((product) => product._id === item?._id);
  const cartItems = cart.find((product) => product._id === item?._id);

  return (
    <div className="text-[10px] sm:text-xs">
      {!added && (
        <p
          className="font-semibold text-center bg-green-500 px-5 text-[#fff] py-1 rounded cursor-pointer"
          onClick={() =>
            dispatch(
              addToCart({
                imgUrl: item.imgUrl,
                _id: item?._id,
                name: item.name,
                price:
                  selectedQuantities[item?._id]?.averagePrice ||
                  item.variants[0]?.averagePrice,
                quantity: 1,
                unit: selectedQuantities[item?._id]?.unit,
              })
            )
          }
        >
          ADD
        </p>
      )}

      {added && (
        <div className="flex items-center gap-2 bg-green-500 py-[1.3px] sm:py-0 text-[#fff] rounded">
          <button
            className="px-[7px] text-sm sm:text-base"
            onClick={() => dispatch(removeFromCart(item?._id))}
          >
            -
          </button>

          <span className="text-xs sm:text-sm font-semibold">{cartItems?.quantity}</span>

          <button
            className="px-[7px] text-sm sm:text-base"
            onClick={() =>
              dispatch(
                addToCart({
                  imgUrl: item.imgUrl,
                  _id: item?._id,
                  name: item.name,
                  price:
                    selectedQuantities[item?._id]?.averagePrice ||
                    item.variants[0]?.averagePrice,
                  quantity: cartItems?.items ?? 1,
                  unit: selectedQuantities[item?._id]?.unit,
                })
              )
            }
          >
            +
          </button>
        </div>
      )}
    </div>
  );
}

export default CartButton;
