import React, { useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
// import { deleteFromCart, resetCart } from "../redux/slices/randomCartSlice";
import AppointmentTiming from "../../components/AppointmentTiming";
// import BookingDiscount from "../components/BookingDiscount";
import { axiosClient } from "../../utils/axiosClient";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { deleteFromCart, resetCart } from "../../redux/slices/barberCart";
import BookingDiscount from "../../components/BookingDiscount";
import { handlePayment } from "../../utils/handlePayment";

const BookAppointment = () => {
  const { shopId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [view, setView] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);

  const items = useSelector((state) => state?.barberCart?.cart);
  const totalPrice = items.reduce((acc, data) => acc + Number(data.price), 0);
  const discountPercent = 10;
  const discountedPrice = parseInt(
    (totalPrice - (totalPrice * discountPercent) / 100).toFixed(2)
  );

  const controls = useAnimation();
  const toggleView = async () => {
    setView((prev) => !prev);
    await controls.start({ height: view ? 0 : "auto" });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const onBookAppointment = async (payNow) => {
    const selectedServices = items.map((service) => service._id);
    try {
      const date = new Date(selectedDate).toISOString().split("T")[0];
      const res = await axiosClient.post(`/appointment/${shopId}`, {
        date,
        fromTime: selectedTime,
        selectedServices,
      });
      const appointmentId = res.result.appointmentId;
      toast.success(res.result.appointmentStatus);
      if (payNow) {
        const paymentSuccess = await handlePayment(
          discountedPrice,
          appointmentId,
          "appointment"
        );
        if (paymentSuccess) {
          dispatch(resetCart());
          navigate("/allAppointments");
        }
      } else {
        dispatch(resetCart());
        navigate("/allAppointments");
      }
    } catch (e) {
      return;
    }
  };

  return (
    <div className="p-4 max-w-3xl mx-auto">
      <AppointmentTiming
        onDateChange={handleDateChange}
        onTimeChange={handleTimeChange}
      />

      <div className="bg-white p-4 rounded-lg shadow-md mt-4">
        <h2 className="text-lg font-semibold mb-2">Bill Details</h2>

        <button
          onClick={toggleView}
          className="flex justify-between w-full py-2 px-4 bg-gray-200 rounded-lg"
        >
          <span>Your Services</span>
          <span>{view ? "▲" : "▼"}</span>
        </button>

        <motion.div
          animate={controls}
          initial={{ height: 0 }}
          className="overflow-hidden"
        >
          {items.map((service) => (
            <div
              key={service._id}
              className="flex justify-between items-center py-2 border-b"
            >
              <div>
                <p className="font-medium">{service.title}</p>
                <p className="text-sm text-gray-500">₹ {service.price}</p>
              </div>
              <button
                onClick={() => dispatch(deleteFromCart(service.serviceId))}
                className="text-red-500"
              >
                ✖
              </button>
            </div>
          ))}
        </motion.div>

        <div className="flex justify-between mt-4 border-t pt-2">
          <span className="font-semibold">Approx Total</span>
          <span className="font-semibold">₹ {totalPrice}</span>
        </div>
      </div>

      <BookingDiscount
        originalPrice={totalPrice}
        discountPercent={discountPercent}
      />

      <div className="flex gap-4 mt-4">
        <button
          onClick={() => onBookAppointment(true)}
          className="w-1/2 py-2 bg-blue-500 text-white rounded-lg"
        >
          Book & Pay Now
        </button>
        <button
          onClick={() => onBookAppointment(false)}
          className="w-1/2 py-2 bg-gray-500 text-white rounded-lg"
        >
          Book & Pay Later
        </button>
      </div>
    </div>
  );
};

export default BookAppointment;
