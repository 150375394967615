import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  cart: [],
};

const randomCartSlice = createSlice({
  name: "randomCartSlice",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const curProduct = action.payload;

      const index = state.cart.findIndex(
        (item) => item.serviceId === curProduct.serviceId
      );

      if (index === -1) {
        state.cart.push({ ...curProduct });
      }
    },
    deleteFromCart: (state, action) => {
      state.cart = state.cart.filter(
        (item) => item.serviceId !== action.payload
      );
    },
    resetCart: (state) => {
      state.cart = [];
    },
    updatePrice: (state, action) => {
      const { serviceId, price, _id } = action.payload;

      const index = state.cart.findIndex(
        (item) => item.serviceId === serviceId
      );
      if (index !== -1) {
        // Update price if item exists
        state.cart[index].price = price;
        state.cart[index]._id = _id;
      }
    },
  },
});

export const { addToCart, deleteFromCart, resetCart, updatePrice } =
  randomCartSlice.actions;
export default randomCartSlice.reducer;
