import { useEffect, useState } from "react";
import { formatDate } from "../../utils/formatDate";
import ConfirmationModal from "../../components/ConfirmationModal";
import { axiosClient } from "../../utils/axiosClient";
import { useParams } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import toast from "react-hot-toast";
import { FaRegCreditCard } from "react-icons/fa6";

const tabData = [
  { key: "pending", title: "Pending" },
  { key: "delivered", title: "Delivered" },
  { key: "cancelled", title: "Cancelled" },
];

const ShopOrders = () => {
  const [index, setIndex] = useState(0);
  const [orders, setOrders] = useState({
    pending: [],
    delivered: [],
    cancelled: [],
  });
  const [orderId, setOrderId] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const params = useParams();

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axiosClient.get(`orders/shop/${params.id}`);
      const fetchedOrders = response.result;
      const categorizedOrders = {
        pending: [],
        delivered: [],
        cancelled: [],
      };

      fetchedOrders.forEach((order) => {
        const status = order.status || "";

        if (status === "Pending") {
          categorizedOrders.pending.push(order);
        } else if (status === "Delivered") {
          categorizedOrders.delivered.push(order);
        } else if (status === "Cancelled") {
          categorizedOrders.cancelled.push(order);
        }
      });
      setOrders(categorizedOrders);
    } catch (e) {
      console.error("Error fetching appointments:", e);
    }
  };

  const onCancelOrder = async () => {
    try {
      handleCloseModal();
      updateOrderStatus(orderId, "Cancelled");
    } catch (error) {}
  };

  const handleOpenModal = (orderId) => {
    setOrderId(orderId);
    return setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setOrderId("");
  };

  const shortenProductName = (productName) => {
    if (!productName || typeof productName !== "string")
      return "Product name unavailable";

    const words = productName.split(" ");

    // If the name is too short, return as is
    if (words.length <= 3) return productName;

    // Extract the brand (first word)
    const brand = words[0];

    // Find the first keyword that represents the product type
    const keywords = [
      "Atta",
      "Rice",
      "Flour",
      "Sugar",
      "Oil",
      "Dal",
      "Salt",
      "Milk",
    ];
    const productType =
      words.find((word) => keywords.includes(word)) || words[1];

    // Extract the weight/size (if mentioned in brackets)
    const sizeMatch = productName.match(/\((.*?)\)/);
    const size = sizeMatch ? sizeMatch[0] : "";

    return `${brand} ${productType} ${size}`.trim();
  };

  const openDialer = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  const updateOrderStatus = async (orderId, status) => {
    try {
      const res = await axiosClient.put(
        `orders/shop/updateStatus/${params.id}/${orderId}/${status}`
      );
      fetchOrders();
      toast.success(res.result);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      {/* TABS */}
      <div className="flex md:justify-center space-x-4 overflow-x-auto p-2 text-xs sm:text-sm hideScrollbar">
        {tabData.map((tab, idx) => (
          <button
            key={tab.key}
            className={`px-4 py-2 whitespace-nowrap rounded-full border transition ${
              index === idx
                ? "bg-[#607d8b] text-white"
                : "border-[#607d8b] text-[#607d8b]"
            }`}
            onClick={() => setIndex(idx)}
          >
            {tab.title} ({orders[tab.key].length})
          </button>
        ))}
      </div>

      {/* APPOINTMENTS CARD */}
      <div className="mt-4">
        {orders[tabData[index].key].length === 0 ? (
          <p className="text-center text-[#777]">No Orders</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {orders[tabData[index].key]?.map((order) => {
              const totalPrice = order.products.reduce(
                (acc, item) => acc + item.totalAmount,
                0
              );
              return (
                <div
                  key={order.orderId}
                  className="p-4 border border-[#ccc] bg-white rounded-lg shadow-md"
                >
                  <div className="flex justify-between items-center">
                    <p
                      className={
                        order.status === "Pending" ||
                        order.status === "Cancelled"
                          ? "text-red-500"
                          : "text-green-500"
                      }
                    >
                      {order.status}
                    </p>
                    {(order.status === "Pending" ||
                      order.status === "Confirmed") && (
                      <button
                        className="text-red-500 border border-red-500 px-2 py-1 rounded"
                        onClick={() => handleOpenModal(order.orderId)}
                      >
                        CANCEL
                      </button>
                    )}
                  </div>
                  <div className="my-2 flex justify-between text-xs">
                    <p>Order placed</p>
                    <p className="text-[#555]">{formatDate(order.orderDate)}</p>
                  </div>

                  {order.deliveryDate &&
                    order.deliveryTime &&
                    order.status === "Pending" && (
                      <div className="my-2 flex justify-between text-xs">
                        <p>Scheduled Delivery</p>

                        <p className="text-[#555]">
                          {formatDate(order.deliveryDate)} -{" "}
                          {order.deliveryTime}
                        </p>
                      </div>
                    )}

                  {order.status === "Delivered" && order.deliveredDate && (
                    <div className="my-2 flex justify-between text-xs">
                      <p>Delivered</p>
                      <p className="text-[#555]">
                        {formatDate(order.deliveredDate)}
                      </p>
                    </div>
                  )}

                  <div className="flex justify-between items-center mb-1">
                    <span className="text-gray-600 font-bold">
                      {order.customerName}
                    </span>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => openDialer(order.customerPhone)}
                        className="p-2 rounded-full bg-green-500 text-white flex items-center justify-center hover:bg-green-600 transition"
                      >
                        <FaPhoneAlt size={14} />
                      </button>
                      <button
                        onClick={() =>
                          (window.location.href = `mailto:${order.customerEmail}`)
                        }
                        className="p-2 rounded-full bg-blue-500 text-white flex items-center justify-center hover:bg-blue-600 transition"
                      >
                        <MdEmail size={16} />
                      </button>
                    </div>
                  </div>

                  {/* SERVICES */}
                  <p className="border-b border-dashed border-[#494949] text-[#494949]">
                    Services:{" "}
                  </p>
                  <div className="flex overflow-x-auto hideScrollbar gap-4 mt-2 p-2">
                    {order.products.map((product) => (
                      <div
                        key={product.productId}
                        className="flex flex-col gap-2 p-4 border border-gray-300 rounded-lg bg-white shadow-md min-w-[180px] max-w-[200px]"
                      >
                        {/* Product Image */}
                        <div className="w-full h-24 flex items-center justify-center">
                          <img
                            src={product.imgUrl}
                            alt={product.name}
                            className="w-full h-full object-cover rounded-md"
                          />
                        </div>

                        {/* Product Name */}
                        <p className="text-sm font-medium text-gray-700 text-center">
                          {shortenProductName(product.name)}
                        </p>

                        {/* Price & Unit */}
                        <div className="flex justify-between items-center text-xs text-gray-600">
                          <p className="font-semibold text-gray-800">
                            ₹ {product.price}
                          </p>
                          <p className="bg-gray-200 px-2 py-1 rounded-md text-[10px] font-medium">
                            {product.unit}
                          </p>
                        </div>

                        {/* Subtotal & Quantity */}
                        <div className="flex justify-between items-center text-xs text-gray-600">
                          <p className="font-medium">
                            Subtotal:{" "}
                            <span className="text-gray-800 font-semibold">
                              ₹{product.totalAmount}
                            </span>
                          </p>
                          <p className="bg-blue-100 text-blue-600 px-2 py-1 rounded-md text-[10px] font-semibold">
                            Qty: {product.quantity}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex justify-between items-center text-sm">
                    <p className="pt-2">
                      {" "}
                      Total: <span className="font-medium">
                        ₹{totalPrice}
                      </span>{" "}
                    </p>
                    {order.status === "Pending" && (
                      <p
                        className="bg-[#607d8b] px-4 py-2 rounded text-[#fff] border hover:border-[#607d8b] hover:text-[#607d8b] hover:bg-[#fff] cursor-pointer"
                        onClick={() =>
                          updateOrderStatus(order.orderId, "Delivered")
                        }
                      >
                        Delivered
                      </p>
                    )}
                  </div>

                  {/* PAYMENT */}
                  <div className="flex items-center justify-between mt-2">
                    <div className="flex items-center gap-2">
                      <p className="text-[#607d8b]">
                        <FaRegCreditCard />
                      </p>
                      <p
                        className={`${
                          order.paymentStatus === "Pending"
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {order.paymentStatus}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* MODALS */}

      <ConfirmationModal
        show={modalVisible}
        onClose={handleCloseModal}
        onConfirm={onCancelOrder}
        message="You want to cancel this order?"
        appointmentAction="No, keep it"
        action="Yes, cancel it"
      />
    </div>
  );
};

export default ShopOrders;
