import React, { useState } from "react";
import { handlePayment } from "../../utils/handlePayment";
import { AiOutlineClose } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { resetCart } from "../../redux/slices/groceryCartSlice";
import { axiosClient } from "../../utils/axiosClient";
import toast from "react-hot-toast";
import { extractTimeFromTimestamp } from "../../utils/formatDate";

function BookOrder({ onClose, price, cart, shopId }) {
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    setTimeout(onClose, 400);
  };

  const onPayment = async (payNow) => {
    try {
      const products = cart.map((item) => {
        return {
          productId: item._id,
          unit: item.unit,
          quantity: item.quantity,
        };
      });

      const response = await axiosClient.post("orders/", {
        shopId,
        products,
        date: new Date(date).toISOString(),
        time: extractTimeFromTimestamp(time),
      });

      setTime(null);
      setDate(new Date());

      const orderId = response.result.orderId;

      if (payNow) {
        const paymentSuccess = await handlePayment(price, orderId, "order");

        if (paymentSuccess) {
          dispatch(resetCart());
          handleCloseClick();
        }
      } else {
        dispatch(resetCart());
        handleCloseClick();
      }

      toast.success(response.result.message);
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const getMinTime = () => {
    const now = new Date();
    now.setHours(now.getHours() + 2, 0, 0, 0);
    return now;
  };

  const label = "text-xs sm:text-sm font-medium mb-1";
  const inputStyle =
    "border border-gray-300 rounded-md p-2 w-full text-center focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer";

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-md text-center w-full max-w-md mx-4 sm:mx-auto">
        <div className="flex items-center justify-between font-bold text-lg px-5 py-2 rounded-t-lg bg-[#607d8b] text-[#fff]">
          <p>Choose Slot</p>
          <AiOutlineClose
            className="cursor-pointer"
            onClick={handleCloseClick}
          />
        </div>

        <div className="p-5">
          <div className="flex gap-4">
            {/* Date Picker */}
            <div>
              <p className={`${label}`}>Select Delivery Date (Optional)</p>
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select Date"
                minDate={new Date()}
                className={inputStyle}
              />
            </div>

            {/* Time Picker */}
            <div>
              <p className={`${label}`}>Select Delivery Time (Optional)</p>
              <DatePicker
                selected={time}
                onChange={(date) => setTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                placeholderText="Select Time"
                minTime={
                  date && date.toDateString() === new Date().toDateString()
                    ? getMinTime()
                    : new Date(0, 0, 0, 0, 0, 0)
                }
                maxTime={new Date(0, 0, 0, 23, 45, 0)}
                className={inputStyle}
              />
            </div>
          </div>

          <div className="flex gap-4 mt-4">
            <button
              onClick={() => onPayment(true)}
              className="w-1/2 py-2 bg-blue-500 text-white rounded-lg"
            >
              Order & Pay Now
            </button>
            <button
              onClick={() => onPayment(false)}
              className="w-1/2 py-2 bg-gray-500 text-white rounded-lg"
            >
              Order & Pay Later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookOrder;
