import React, { useEffect, useState } from "react";
import { axiosClient } from "../../utils/axiosClient";
import CartButton from "./CartButton";
import { getLocation } from "../../utils/getLocation";
import QuantitySelector from "./QuantitySelector";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

function Seasonal() {
  let season = "Summer";
  const [products, setProducts] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState({});

  useEffect(() => {
    try {
      const getProducts = async () => {
        const location = await getLocation();
        if (location) {
          const response = await axiosClient.get(
            `/grocery/seasons?latitude=${location?.latitude}&longitude=${location?.longitude}&season=${season}&limit=10`
          );
          setProducts(response.result);
        }
      };
      getProducts();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const initialQuantities = {};

    products.forEach((product) => {
      if (product.variants.length > 0) {
        initialQuantities[product._id] = product.variants[0];
      }
    });

    setSelectedQuantities(initialQuantities);
  }, [products]);

  return (
    <div className="relative bg-green-50 pt-10">
      <div className="flex gap-4 px-4 pb-4 w-screen overflow-x-scroll hideScrollbar">
        {products.map((product) => (
          <div
            key={product._id}
            className="w-40 md:w-48 bg-white rounded-lg shadow-md hover:shadow-lg cursor-pointer"
          >
            <img
              src={product.imgUrl.url}
              alt={product.name}
              className="w-full h-40 md:h-48 object-cover rounded-t-lg"
              loading="lazy"
            />
            <div className="w-40 md:w-48 p-2 text-[10px] sm:text-xs space-y-2">
              <div className="flex justify-between items-center">
                <p className="font-medium">{product.name}</p>

                <CartButton
                  item={product}
                  selectedQuantities={selectedQuantities}
                />
              </div>
              <div className="flex justify-between items-center">
                <p>
                  ₹{" "}
                  {selectedQuantities[product._id]?.averagePrice ||
                    product.variants[0]?.averagePrice}{" "}
                  <span className="text-[#999] text-[8px]">(Avg. Price)</span>
                </p>
                <QuantitySelector
                  item={product}
                  selectedQuantities={selectedQuantities}
                  setSelectedQuantities={setSelectedQuantities}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <Link
        to="/seasonal-products"
        className="absolute flex items-center gap-2 top-1 right-5 text-blue-500 transition-transform duration-300 hover:scale-110"
      >
        <p>See All</p>
        <AiOutlineArrowRight size={18} />
      </Link>
    </div>
  );
}

export default Seasonal;
