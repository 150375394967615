import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomerReviews from "../../components/CustomerReviews";
import { isShopOpen } from "../../utils/shopStatus";
import { axiosClient } from "../../utils/axiosClient";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { FaAngleRight } from "react-icons/fa6";
import { MdMyLocation } from "react-icons/md";
import DistanceCalculator from "../../components/DistanceCalculator";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { updatePrice } from "../../redux/slices/barberCart";

const Shop = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [shopData, setShopData] = useState(null);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [review, setReview] = useState([]);
  const [activeTab, setActiveTab] = useState("Services");
  const sectionRefs = useRef({
    Services: null,
    About: null,
    Reviews: null,
  });

  const sections = [
    { id: "Services", title: "Services" },
    { id: "About", title: "About" },
    { id: "Reviews", title: "Reviews" },
  ];

  const cartItems = useSelector((state) => state.barberCart.cart);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          toast.error("Unable to retrieve your location.");
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      toast.error("Geolocation is not supported by your browser.");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosClient.get(`/shop/detailsForCustomer/${id}`);
        setShopData(res?.result);

        const reviewRes = await axiosClient.get(`/rate/ratings/${id}`);
        setReview(reviewRes?.result);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (shopData) {
      handlePriceUpdate();
    }
  }, [shopData]);

  const handlePriceUpdate = () => {
    cartItems.forEach((item) => {
      const service = shopData?.features
        .flatMap((feature) => feature)
        .flatMap((items) => items.services)
        .find((service) => service.serviceId === item.serviceId);
      if (service) {
        dispatch(
          updatePrice({
            serviceId: item.serviceId,
            price: service.price,
            _id: service._id,
          })
        );
      }
    });
  };

  const scrollToSection = (id) => {
    const section = sectionRefs.current[id];
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setActiveTab(id);
  };

  const openMap = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${shopData?.location.coordinates[1]},${shopData?.location.coordinates[0]}`;
    window.open(url, "_blank");
  };

  const dayMap = {
    Sunday: "Su",
    Monday: "M",
    Tuesday: "Tu",
    Wednesday: "W",
    Thursday: "Th",
    Friday: "F",
    Saturday: "Sa",
  };

  // Reverse dayMap to get full names from short names
  const reverseDayMap = Object.fromEntries(
    Object.entries(dayMap)?.map(([full, short]) => [short, full])
  );

  // Get today's short name (e.g., "M")
  const today = new Date().toLocaleString("en-US", { weekday: "long" });
  const currentDay = dayMap[today]; // e.g., "M"

  // Example shop operating days
  const operatingDays = ["Su", "M", "W", "F", "Sa"]; // Replace with shopData.operatingDays

  // Map days to indices for easy comparison
  const dayOrder = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];
  const todayIndex = dayOrder.indexOf(currentDay);

  // Find the next available day
  let day;

  // Loop through operatingDays to find the next available day
  for (let i = 1; i <= 7; i++) {
    const nextDayIndex = (todayIndex + i) % 7; // Circular increment
    const nextDayShort = dayOrder[nextDayIndex]; // Short name like "W"
    if (operatingDays.includes(nextDayShort)) {
      day = reverseDayMap[nextDayShort]; // Convert short name to full name
      break;
    }
  }

  const operatingHours = shopData?.operatingHours || "";
  const [opens, closes] = operatingHours
    .split(" - ")
    .map((time) => time.trim());

  return (
    <div className="flex flex-col bg-white">
      <div className="flex flex-col md:flex-row md:items-start md:justify-around md:py-4">
        {/* Fixed Header */}
        <div className="w-full h-64 md:size-80 lg:size-96 bg-gray-200 flex justify-center items-center">
          {shopData?.imgUrl?.url && (
            <img
              src={shopData.imgUrl.url}
              alt="Shop"
              className="w-full h-full object-cover"
            />
          )}
        </div>

        {/* Fixed Shop Details */}
        <div className="p-4 md:p-0">
          <h1 className="text-lg md:text-2xl font-semibold">
            {shopData?.shopName}
          </h1>
          <p className="text-sm md:text-base text-gray-600">
            {shopData?.shopAddress}
          </p>

          <div className="flex flex-col space-y-2 mt-2 text-sm md:text-base">
            <div className="flex items-center md:flex-col md:items-start space-x-2 md:space-x-0 md:space-y-2">
              {isShopOpen(shopData?.operatingDays, shopData?.operatingHours) ? (
                <span className="flex items-center gap-1 self-start p-1 bg-[#e0e0e0] rounded">
                  <span className="text-green-500">
                    <IoCheckmarkCircle />
                  </span>
                  Open now
                </span>
              ) : (
                <span className="flex items-center gap-1 self-start p-1 bg-[#e0e0e0] rounded">
                  <span className="text-red-500">
                    <IoCloseCircle />
                  </span>
                  Closed now
                </span>
              )}
              <span className="p-1 bg-[#e0e0e0] rounded">
                {isShopOpen(shopData?.operatingDays, shopData?.operatingHours)
                  ? `Closes at ${closes}`
                  : `Opens on ${day} at ${opens}`}
              </span>
            </div>
            <button
              onClick={openMap}
              className="flex items-center self-start gap-1 p-1 bg-[#e0e0e0] rounded"
            >
              <MdMyLocation /> Get Directions |
              {shopData?.location?.coordinates && location && (
                <DistanceCalculator
                  origin={location}
                  destinationCoords={shopData.location.coordinates}
                />
              )}
              <FaAngleRight />
            </button>
          </div>

          <p
            className="p-2 mt-2 bg-blue-500 text-white rounded-md text-sm md:text-base text-center font-semibold cursor-pointer"
            onClick={() => navigate(`/book-appointment/${id}`)}
          >
            Book Appointment
          </p>
        </div>
      </div>
      {/* Fixed Tabs */}
      <div className="flex justify-around bg-gray-100 border-b shadow-md sticky top-0 z-10">
        {sections?.map((section) => (
          <button
            key={section.id}
            onClick={() => scrollToSection(section.id)}
            className={`p-2 text-sm font-semibold border-b-2 ${
              activeTab === section.id
                ? "border-blue-500 text-blue-500"
                : "border-transparent text-gray-600"
            }`}
          >
            {section.title}
          </button>
        ))}
      </div>

      {/* Scrollable Content */}
      <div className="flex-1">
        {/* Services Section */}
        <div
          id="Services"
          ref={(el) => (sectionRefs.current.Services = el)}
          className="p-4"
        >
          <h2 className="text-lg font-semibold mb-4">Services</h2>
          <div className="grid grid-cols-4 gap-4">
            {shopData?.features?.map((feature) =>
              feature?.services?.map((service) => (
                <div key={service._id} className="text-center">
                  <img
                    src={service.imgUrl}
                    alt={service.serviceTitle}
                    className="size-20 sm:size-40 md:size-56 object-cover mx-auto rounded-md"
                  />
                  <h3 className="text-sm font-medium mt-2">
                    {service.serviceTitle}
                  </h3>
                  <p className="text-sm text-gray-600">₹ {service.price}</p>
                </div>
              ))
            )}
          </div>
        </div>

        {/* About Section */}
        <div
          id="About"
          ref={(el) => (sectionRefs.current.About = el)}
          className="p-4"
        >
          <h2 className="text-lg font-semibold mb-2">About</h2>
          <p className="text-gray-600">{shopData?.shopDetail}</p>

          <h2 className="text-lg font-semibold mt-4">Address</h2>
          <p className="text-gray-600">{shopData?.shopAddress}</p>
        </div>

        {/* Reviews Section */}
        <div
          id="Reviews"
          ref={(el) => (sectionRefs.current.Reviews = el)}
          className="p-4 mb-80"
        >
          <h2 className="text-lg font-semibold mb-4">Customer Reviews</h2>
          {review?.length ? (
            review?.map((item, idx) => (
              <CustomerReviews key={idx} review={item} />
            ))
          ) : (
            <p className="text-gray-600">No reviews yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Shop;
