import React, { useEffect, useState } from "react";
import { isShopOpen } from "../../utils/isShopOpen";
import { axiosClient } from "../../utils/axiosClient";
import ShopCard from "../../components/ShopCard";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

const ShopsList = () => {
  const [location, setLocation] = useState(null);
  const [filteredShops, setFilteredShops] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedDistance, setSelectedDistance] = useState("5");
  const Location = useLocation();
  const { items, gender } = Location.state || {};

  const getLocation = async () => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => console.error("Error fetching location:", error),
        { enableHighAccuracy: true }
      );
    } catch (error) {
      console.error("Failed to get location", error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (location) fetchShops();
  }, [selectedDistance, location]);

  const fetchShops = async () => {
    if (!location) {
      toast.error("Location is required");
      return;
    }
    try {
      const res = await axiosClient.get(`shop/features`, {
        params: {
          lat: location.latitude,
          lng: location.longitude,
          maxDistance: parseInt(selectedDistance),
          gender,
          services: items,
        },
      });
      setDisplayData(res.result.shopsList);
      setFilteredShops(res.result.shopsList);
    } catch (e) {
      console.error("Error fetching shops:", e);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [selectedStatus, selectedDistance, displayData]);

  const applyFilters = () => {
    if (displayData?.length === 0) return;
    let filtered = displayData;
    if (selectedStatus === "Open") {
      filtered = filtered?.filter((shop) =>
        isShopOpen(shop.operatingDays, shop.operatingHours)
      );
    } else if (selectedStatus === "Close") {
      filtered = filtered?.filter(
        (shop) => !isShopOpen(shop.operatingDays, shop.operatingHours)
      );
    }

    filtered?.sort((a, b) => {
      const isAOpen = isShopOpen(a.operatingDays, a.operatingHours);
      const isBOpen = isShopOpen(b.operatingDays, b.operatingHours);
      return isAOpen === isBOpen ? 0 : isAOpen ? -1 : 1;
    });

    setFilteredShops(filtered);
  };

  return (
    <div className="p-4">
      {/* Dropdown Filters */}
      <div className="flex flex-wrap gap-4 justify-center mb-4">
        <select
          className="p-2 border rounded-md"
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Open">Open</option>
          <option value="Close">Close</option>
        </select>

        <select
          className="p-2 border rounded-md"
          onChange={(e) => setSelectedDistance(e.target.value)}
        >
          <option value="5">5 km</option>
          <option value="10">10 km</option>
          <option value="15">15 km</option>
          <option value="20">20 km</option>
        </select>
      </div>

      {/* Shop List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredShops?.map((data) => (
          <ShopCard data={data} key={data.shopId} />
        ))}
      </div>
    </div>
  );
};

export default ShopsList;
