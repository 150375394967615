import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../../utils/axiosClient";
import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { ImCancelCircle } from "react-icons/im";
import Service from "../../components/Service";
import { getItem, setItem } from "../../utils/localStorageManager";

function ShopFeatures() {
  const [isFemale, setIsFemale] = useState(() => {
    return getItem("isFemale") === "true";
  });
  const [isFocused, setIsFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFemaleItems, setSelectedFemaleItems] = useState([]);
  const [services, setServices] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setItem("isFemale", isFemale.toString());
    const api = async () => {
      try {
        const res = await axiosClient.get(
          `/allServices/${isFemale ? "Female" : "Male"}`
        );
        setServices(res.result[0].services);
      } catch (error) {
        console.log("error", error);
      }
    };
    api();
  }, [isFemale]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredServices(services);
    } else {
      const filtered = services.filter((service) =>
        service.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredServices(filtered);
    }
  }, [searchQuery, services]);

  const toggleSwitch = () => {
    setIsFemale((previousState) => !previousState);
  };

  const handleToggleSelection = (item) => {
    {
      isFemale &&
        setSelectedFemaleItems((prevItems) => {
          const alreadySelected = prevItems.find((i) => i._id === item._id);
          const updatedItems = alreadySelected
            ? prevItems.filter((i) => i._id !== item._id)
            : [...prevItems, item];

          return updatedItems;
        });
    }

    {
      !isFemale &&
        setSelectedItems((prevItems) => {
          const alreadySelected = prevItems.find((i) => i._id === item._id);
          const updatedItems = alreadySelected
            ? prevItems.filter((i) => i._id !== item._id)
            : [...prevItems, item];

          return updatedItems;
        });
    }
  };

  const handleRemoveItem = (id) => {
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item._id !== id)
    );
  };

  const handleRemoveFemaleItem = (id) => {
    setSelectedFemaleItems((prevItems) =>
      prevItems.filter((item) => item._id !== id)
    );
  };

  const onNavigate = () => {
    const maleServices = selectedItems;
    const femaleServices = selectedFemaleItems;

    navigate("/shop-feature-price", {
      state: { maleServices, femaleServices },
    });
  };

  return (
    <div className="w-full flex flex-col items-center p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 text-center">
        Select Services You Offer
      </h2>
      {/* SEARCH BAR */}
      <div
        className={`flex items-center border rounded-lg px-3 py-2 w-full max-w-md mx-auto transition-colors
      ${
        isFocused
          ? isFemale
            ? "border-[#FFC1E3]"
            : "border-[#81b0ff]"
          : "border-gray-300"
      }
    `}
      >
        <AiOutlineSearch
          size={18}
          className={`${isFemale ? "text-[#FF1493]" : "text-[#81b0ff]"}`}
        />
        <input
          type="text"
          className="w-full p-2 outline-none bg-transparent text-sm sm:text-base"
          placeholder={`Search for ${isFemale ? '"Facial"' : '"Beard Trim"'}`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>

      {/* TOGGLE GENDER */}
      <div className="mt-4 flex items-center space-x-3">
        <span
          className={`text-base md:text-lg font-semibold ${
            !isFemale ? "text-[#1E90FF]" : "text-[#555]"
          }`}
        >
          Male
        </span>
        <button
          className={`w-14  h-7 flex items-center rounded-full p-1 transition duration-300 ${
            isFemale ? "bg-[#FFC1E3]" : "bg-[#81b0ff]"
          }`}
          onClick={toggleSwitch}
        >
          <div
            className={`h-5 w-5 bg-white rounded-full shadow-md transform ${
              isFemale ? "translate-x-7" : "translate-x-0"
            } ${
              isFemale ? "bg-[#FF1493]" : "bg-[#1E90FF]"
            } transition duration-300`}
          ></div>
        </button>
        <span
          className={`text-base md:text-lg font-semibold ${
            isFemale ? "text-[#FF1493]" : "text-[#555]"
          }`}
        >
          Female
        </span>
      </div>

      {/* SELECTED MALE ITEMS LIST */}
      {selectedItems.length > 0 && !isFemale && (
        <div className="border-b border-gray-300 flex justify-center">
          <div>
            <h3 className="font-medium text-sm my-2 text-center">
              Selected Male Services
            </h3>

            <div className="flex md:justify-center space-x-4 w-96 overflow-x-auto hideScrollbar">
              {selectedItems.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="flex flex-col items-center snap-center"
                  >
                    <div className="relative">
                      <img
                        src={item.imgUrl.url}
                        alt={item.title}
                        className="rounded-md w-20 h-20 min-w-[5rem] object-cover"
                      />
                      <button
                        onClick={() => handleRemoveItem(item._id)}
                        className={`absolute top-1 right-1 rounded-full p-1 z-10 text-white 
                  ${
                    isFemale ? "bg-pink-500" : "bg-blue-500"
                  } hover:scale-110 transition`}
                      >
                        <ImCancelCircle size={14} />
                      </button>
                    </div>
                    <p className="mt-1 text-xs text-center">{item.title}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* SELECTED FEMALE ITEMS LIST */}
      {selectedFemaleItems.length > 0 && isFemale && (
        <div className="border-b border-gray-300 flex justify-center">
          <div>
            <h3 className="font-medium text-sm my-2 text-center">
              Selected Female Services
            </h3>

            <div className="flex md:justify-center space-x-4 w-96 overflow-x-auto hideScrollbar">
              {selectedFemaleItems.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="flex flex-col items-center snap-center"
                  >
                    <div className="relative">
                      <img
                        src={item.imgUrl.url}
                        alt={item.title}
                        className="rounded-md w-20 h-20 min-w-[5rem] object-cover"
                      />
                      <button
                        onClick={() => handleRemoveFemaleItem(item._id)}
                        className={`absolute top-1 right-1 rounded-full p-1 z-10 text-white 
                        ${
                          isFemale ? "bg-[#FF1493]" : "bg-[#1E90FF]"
                        } hover:scale-110 transition`}
                      >
                        <ImCancelCircle size={14} />
                      </button>
                    </div>
                    <p className="mt-1 text-xs text-center">{item.title}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {services?.length > 0 && (
        <Service
          services={filteredServices}
          selectedItems={isFemale ? selectedFemaleItems : selectedItems}
          onToggleSelection={handleToggleSelection}
          isFemale={isFemale}
        />
      )}

      <button
        className={`flex items-center justify-center border rounded-md px-4 py-2 text-lg font-medium w-fit mx-auto mb-3 transition-colors
      ${
        isFemale
          ? "text-pink-500 border-pink-500"
          : "text-blue-500 border-blue-500"
      }
    `}
        onClick={onNavigate}
      >
        NEXT
        <AiOutlineArrowRight
          size={18}
          className={`ml-2 ${isFemale ? "text-pink-500" : "text-blue-500"}`}
        />
      </button>
    </div>
  );
}

export default ShopFeatures;
