import { useEffect, useState } from "react";
import { axiosClient } from "../../utils/axiosClient";
import { useNavigate } from "react-router-dom";

const CategorySection = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const handleNavigate = (categoryId) => {
    navigate("/grocy-go-products", { state: { categoryId } });
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await axiosClient.get("grocery/getCategory");
        setCategories(res.result);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    getCategories();
  }, []);

  const renderCategoryRows = () => {
    const categoryRows = [];
    for (let i = 0; i < categories.length; i += 2) {
      categoryRows.push(categories.slice(i, i + 2));
    }
    return categoryRows;
  };

  return (
    <div className="flex gap-4 md:justify-center p-4 w-screen sm:w-auto overflow-x-scroll hideScrollbar">
      {renderCategoryRows().map((catGroup, idx) => (
        <div className="flex flex-col gap-4" key={idx}>
          {catGroup.map((category) => (
            <div
              key={category._id}
              className="w-40 md:w-48 lg:w-56 bg-white rounded-lg shadow-md hover:shadow-lg cursor-pointer transition-all duration-300"
              onClick={() => handleNavigate(category._id)}
            >
              <img
                src={category.imgUrl}
                alt={category.name}
                className="w-full h-32 object-cover rounded-t-lg"
                loading="lazy"
              />
              <div className="p-2 text-center">
                <p className="text-sm md:text-base font-semibold text-gray-700">
                  {category.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CategorySection;
