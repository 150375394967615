import React from "react";

const Service = ({ services, selectedItems, onToggleSelection, isFemale }) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 p-4">
      {services.map((service) => {
        const isSelected = selectedItems.some(
          (selected) => selected.serviceId === service._id
        );

        return (
          <div
            key={service._id}
            className={`relative w-24 sm:w-32 md:w-40 lg:w-48 xl:w-56 rounded-lg shadow-lg cursor-pointer transition-all duration-300 transform hover:scale-105
                        ${
                          isSelected && !isFemale
                            ? "border-2 border-blue-500"
                            : ""
                        }
                        ${
                          isSelected && isFemale
                            ? "border-2 border-pink-500"
                            : ""
                        }`}
            onClick={() => onToggleSelection(service)}
          >
            <img
              src={service.imgUrl.url}
              alt={service.title}
              className="w-full h-24 sm:h-32 md:h-40 lg:h-48 xl:h-56 object-cover rounded-t-lg"
            />
            <p className="text-center text-sm sm:text-base md:text-lg font-medium mt-2">
              {service.title}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Service;
