import React, { useState, useEffect } from "react";
import { getDistance } from "../utils/distance";

const DistanceCalculator = ({ origin, destinationCoords }) => {
  const [distance, setDistance] = useState(null);
  const lng = destinationCoords[0];
  const lat = destinationCoords[1];

  const destination = { lat, lng };

  useEffect(() => {
    const calculateDistance = async () => {
      if (
        origin &&
        destination &&
        origin.lat !== destination.lat &&
        origin.lng !== destination.lng
      ) {
        const distanceKm = await getDistance(origin, destination);
        setDistance(distanceKm);
      } else {
        setDistance(0);
      }
    };

    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          calculateDistance();
        }
      });
  }, [origin, destination]);

  return (
    <div>
      <span>{distance?.toFixed(2)} kms</span>
    </div>
  );
};

export default DistanceCalculator;

// const originLat = "28.9747488324872";
// const originLng = "77.70001664964705";
// const Origin = { lat: "28.9747488324872", lng: "77.70001664964705" };
