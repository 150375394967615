import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { axiosClient } from "../../utils/axiosClient";

const ShopFeaturesPrice = () => {
  const [malePrices, setMalePrices] = useState({});
  const [femalePrices, setFemalePrices] = useState({});
  const navigate = useNavigate();
  const Location = useLocation();
  const { maleServices, femaleServices } = Location.state || {};

  const shopData = useSelector((state) => state?.shopsSlice?.shopsData);
  const shopId = shopData._id;

  const handlePriceChange = (id, price, gender) => {
    if (gender === "Male") {
      setMalePrices((prev) => ({ ...prev, [id]: price }));
    } else {
      setFemalePrices((prev) => ({ ...prev, [id]: price }));
    }
  };

  const handleSubmit = async () => {
    const services = [
      {
        gender: "Male",
        services: maleServices.map((service) => ({
          serviceId: service._id,
          serviceTitle: service.title,
          imgUrl: service.imgUrl.url,
          price: malePrices[service._id] || "0",
        })),
      },
      {
        gender: "Female",
        services: femaleServices.map((service) => ({
          serviceId: service._id,
          serviceTitle: service.title,
          imgUrl: service.imgUrl.url,
          price: femalePrices[service._id] || "0",
        })),
      },
    ];

    const maleInvalid = maleServices.some(
      (service) => !malePrices[service._id]
    );
    const femaleInvalid = femaleServices.some(
      (service) => !femalePrices[service._id]
    );

    if (maleInvalid || femaleInvalid) {
      toast.error("Please enter prices for all services before submitting.");
      return;
    }

    try {
      const res = await axiosClient.post(`/shop/addService/${shopId}`, {
        services,
      });
      toast.success(res.result);
      navigate(`/shop-admin/${shopId}`);
    } catch (error) {
      toast.error("Error while adding services. Please try again!");
    }
  };

  const renderService = (item, gender) => (
    <div
      key={item._id}
      className="flex flex-col items-center bg-gray-100 p-3 rounded-lg shadow-md"
    >
      <img
        src={item.imgUrl.url}
        alt={item.title}
        className="w-24 h-24 object-cover rounded-md"
      />
      <p className="mt-2 font-medium text-center">
        {item.title.replace(/\s*\(.*?\)\s*/g, "")}
      </p>
      <input
        type="number"
        placeholder="Price (₹)"
        className="border border-gray-300 rounded-md px-2 py-1 w-full text-center mt-2"
        value={
          gender === "Male"
            ? malePrices[item._id] || ""
            : femalePrices[item._id] || ""
        }
        onChange={(e) => handlePriceChange(item._id, e.target.value, gender)}
      />
    </div>
  );

  return (
    <div className="max-w-5xl mx-auto p-4">
      {maleServices.length > 0 && (
        <div>
          <h2 className="text-center text-lg font-semibold mb-4">
            Selected Male Services
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {maleServices.map((service) => renderService(service, "Male"))}
          </div>
        </div>
      )}

      {femaleServices.length > 0 && (
        <div className="mt-6">
          <h2 className="text-center text-lg font-semibold mb-4">
            Selected Female Services
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {femaleServices.map((service) => renderService(service, "Female"))}
          </div>
        </div>
      )}

      <div className="flex justify-center mt-6">
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ShopFeaturesPrice;
