const QuantitySelector = ({
  item,
  selectedQuantities,
  setSelectedQuantities,
}) => {
  return (
    <select
      className="px-[10px] py-1 border rounded-md bg-white text-xs"
      value={selectedQuantities[item._id]?.unit || item.variants[0]?.unit}
      onChange={(e) =>
        setSelectedQuantities({
          ...selectedQuantities,
          [item._id]: item.variants.find(
            (variant) => variant.unit === e.target.value
          ),
        })
      }
    >
      {item.variants.map((variant) => (
        <option key={variant.unit} value={variant.unit}>
          {variant.unit.replace("pieces", "pcs")}
        </option>
      ))}
    </select>
  );
};

export default QuantitySelector;
