import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../../utils/axiosClient";
import toast from "react-hot-toast";
import { getShopAdmin } from "../../redux/slices/shopsSlice";
import { MdMyLocation } from "react-icons/md";
import { getLocation } from "../../utils/getLocation";
import { extractTimeFromTimestamp } from "../../utils/formatDate";

const initialFormData = {
  shopImg: "",
  shopName: "",
  email: "",
  phone: "",
  shopAddress: "",
  shopDetail: "",
  fromTime: new Date(),
  toTime: new Date(),
  operatingDays: [],
  lat: "",
  lng: "",
};

const EditShop = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState("");
  const [errmsg, setErrMsg] = useState("");
  const [location, setLocation] = useState({ lat: null, lng: null });
  const dispatch = useDispatch();

  const days = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];

  const shopData = useSelector((state) => state?.shopsSlice?.shopsData);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]:
        field === "phone"
          ? Number(value)
          : typeof value === "function"
          ? value(prev[field])
          : value,
    }));
  };

  const handleUpdate = async () => {
    if (!formData.shopName) {
      setError("shopName");
      setErrMsg("Shop Name is required.");
      return;
    }

    if (!formData.email) {
      setError("email");
      setErrMsg("Email is required.");
      return;
    }

    if (!formData.phone) {
      setError("contactNumber");
      setErrMsg("Contact Number is required.");
      return;
    }

    const contactInfo = validateContactInfo(
      formData.phone.toString(),
      formData.email
    );

    if (!contactInfo.emailValid) {
      setError("email");
      setErrMsg("Invalid Email.");
      return;
    }

    if (!contactInfo.phoneValid) {
      setError("contactNumber");
      setErrMsg("Contact Number must be of 10 digits.");
      return;
    }

    if (!formData.shopAddress) {
      setError("shopAddress");
      setErrMsg("Shop Address is required.");
      return;
    }

    if (!formData.shopDetail) {
      setError("shopDetail");
      setErrMsg("Shop Detail is required.");
      return;
    }

    if (!formData.fromTime.toLocaleString()) {
      setError("fromTime");
      setErrMsg("Opening Time is required.");
      return;
    }

    if (!formData.toTime.toLocaleString()) {
      setError("toTime");
      setErrMsg("Closing Time is required.");
      return;
    }

    if (formData.operatingDays.length === 0) {
      setError("selectedDays");
      setErrMsg("Select at least one opening day");
      return;
    }

    if (!formData.lat) {
      setError("latitude");
      setErrMsg("Latitude is required to get the exact location of shop.");
      return;
    }

    if (!formData.lng) {
      setError("longitude");
      setErrMsg("Longitude is required to get the exact location of shop.");
      return;
    }

    // Validate latitude and longitude
    if (!validateLatitude(formData.lat.toString())) {
      setError("latitude");
      setErrMsg("Invalid Latitude.");
      return;
    }

    if (!validateLongitude(formData.lng.toString())) {
      setError("longitude");
      setErrMsg("Invalid Longitude.");
      return;
    }

    const inputs = {
      shopName: formData.shopName,
      email: formData.email,
      phone: formData.phone,
      shopAddress: formData.shopAddress,
      shopDetail: formData.shopDetail,
      fromTime: extractTimeFromTimestamp(formData.fromTime),
      toTime: extractTimeFromTimestamp(formData.toTime),
      lat: formData.lat,
      lng: formData.lng,
    };

    try {
      const response = await axiosClient.put(
        `/updateShop/shop/${shopData._id}`,
        {
          inputs,
          operatingDays: formData.operatingDays,
        }
      );
      toast.success(response.result);
      dispatch(getShopAdmin(shopData._id));
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : String(e);
      toast.error(errorMessage);
    }
  };

  // TIME FUNCTIONS
  const convertTo24HourDate = (timeStr) => {
    // Split the input into time and period (AM/PM)
    const [time, period] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number); // Convert hours and minutes to numbers

    // Convert hours based on AM/PM
    if (period === "PM" && hours !== 12) {
      hours += 12; // Add 12 hours for PM times
    }
    if (period === "AM" && hours === 12) {
      hours = 0; // Midnight edge case
    }

    // Create a Date object with the converted time
    const date = new Date();
    date.setHours(hours, minutes, 0, 0); // Set hours and minutes, reset seconds and milliseconds

    return date; // Return the Date object
  };

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}`;
  };

  // DAY SELECTION FUNCTION
  const handleSelectDay = (day) => {
    handleInputChange("operatingDays", (prevState) => {
      if (prevState.includes(day)) {
        return prevState.filter((d) => d !== day);
      } else {
        return [...prevState, day];
      }
    });
  };

  // FORM VALIDATION FUNCTIONS
  function validateContactInfo(phone, email) {
    const isPhoneValid = /^\d{10}$/.test(phone);
    const isEmailValid = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email);

    return {
      phoneValid: isPhoneValid,
      emailValid: isEmailValid,
    };
  }

  const validateLatitude = (lat) => {
    const latitude = parseFloat(lat);
    return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
  };

  const validateLongitude = (lng) => {
    const longitude = parseFloat(lng);
    return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
  };

  useEffect(() => {
    const operatingHours = shopData?.operatingHours;
    const [fromTime, toTime] = operatingHours?.split(" - ");
    const previousData = {
      shopImg: shopData?.imgUrl?.url || "",
      shopName: shopData?.shopName || "",
      shopOwner: shopData?.shopOwner.name || "",
      email: shopData?.email || "",
      phone: shopData?.phone || "",
      shopAddress: shopData?.shopAddress || "",
      shopDetail: shopData?.shopDetail || "",
      fromTime: convertTo24HourDate(fromTime) || new Date(),
      toTime: convertTo24HourDate(toTime) || new Date(),
      operatingDays: shopData?.operatingDays || [],
      lat: shopData.location.coordinates[1] || 0,
      lng: shopData.location.coordinates[0] || 0,
    };

    setFormData(previousData);
  }, [shopData]);

  useEffect(() => {
    const fetchLocation = async () => {
      const location = await getLocation();
      setLocation({
        lat: location.latitude,
        lng: location.longitude,
      });
    };
    fetchLocation();
  }, []);

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Edit Shop</h2>

      {/* IMAGE CONTAINER */}
      <div className="flex justify-center mb-4">
        {formData.shopImg && (
          <img
            src={formData.shopImg}
            alt="Shop"
            className="w-40 h-40 object-cover rounded"
          />
        )}
      </div>

      {/* DETAIL SECTION */}
      <div className="space-y-4">
        <input
          type="text"
          placeholder="Name"
          className="w-full p-2 border rounded"
          value={formData.shopName}
          onChange={(e) => handleInputChange("shopName", e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          className="w-full p-2 border rounded"
          value={formData.email}
          onChange={(e) => handleInputChange("email", e.target.value)}
        />
        <input
          type="text"
          placeholder="Contact Number"
          className="w-full p-2 border rounded"
          value={formData.phone}
          onChange={(e) => handleInputChange("phone", e.target.value)}
        />
        <input
          type="text"
          placeholder="Shop Address"
          className="w-full p-2 border rounded"
          value={formData.shopAddress}
          onChange={(e) => handleInputChange("shopAddress", e.target.value)}
        />
        <input
          type="text"
          placeholder="Shop Detail"
          className="w-full p-2 border rounded"
          value={formData.shopDetail}
          onChange={(e) => handleInputChange("shopDetail", e.target.value)}
        />

        {/* TIME PICKERS */}
        <div className="flex justify-center space-x-4">
          <DatePicker
            selected={formData.fromTime}
            onChange={(date) => handleInputChange("fromTime", date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="h:mm aa"
            className="w-full text-center p-2 border rounded"
          />
          <DatePicker
            selected={formData.toTime}
            onChange={(date) => handleInputChange("toTime", date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="h:mm aa"
            className="w-full text-center p-2 border rounded"
          />
        </div>

        {/* OPERATING DAYS */}
        <div className="flex space-x-2 justify-center">
          {days.map((day, index) => (
            <button
              key={index}
              className={`p-2 border rounded ${
                formData.operatingDays.includes(day)
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100"
              }`}
              onClick={() => {
                handleSelectDay(day);
                if (error === "selectedDays") setError("");
              }}
            >
              {day}
            </button>
          ))}
        </div>
        {error === "selectedDays" && (
          <p className="text-[#FF4D4D] mt-2">{errmsg}</p>
        )}

        {/* LOCATION */}
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Latitude"
            className="w-full p-2 border rounded"
            value={formData.lat}
            onChange={(e) => handleInputChange("lat", e.target.value)}
          />
          <input
            type="text"
            placeholder="Longitude"
            className="w-full p-2 border rounded"
            value={formData.lng}
            onChange={(e) => handleInputChange("lng", e.target.value)}
          />
        </div>
        {error === "latitude" && (
          <p className="text-[#FF4D4D] mt-2">{errmsg}</p>
        )}
        {error === "longitude" && (
          <p className="text-[#FF4D4D] mt-2">{errmsg}</p>
        )}
        <div className="flex justify-center">
          <button
            className="flex items-center gap-2 text-white bg-[#0099FF] rounded py-2 px-4 "
            onClick={() => {
              handleInputChange("lat", location.lat);
              handleInputChange("lng", location.lng);
            }}
          >
            <MdMyLocation />
            SET CURRENT LOCATION
          </button>
        </div>

        {/* BUTTONS */}
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded"
            onClick={handleUpdate}
          >
            UPDATE
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditShop;
