import React, { useEffect, useState } from "react";
import { axiosClient } from "../../utils/axiosClient";
import { BiSolidCameraPlus } from "react-icons/bi";
import { MdMyLocation } from "react-icons/md";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getShopAdmin } from "../../redux/slices/shopsSlice";
import { getMyInfo } from "../../redux/slices/appConfigSlice";
import { getLocation } from "../../utils/getLocation";
import { extractTimeFromTimestamp } from "../../utils/formatDate";

const initialFormData = {
  shopImg: "",
  shopName: "",
  email: "",
  phone: 0,
  shopAddress: "",
  shopDetail: "",
  type: "",
  category: "",
  gstNo: "",
  fssaiNo: "",
  fromTime: extractTimeFromTimestamp(new Date()),
  toTime: extractTimeFromTimestamp(new Date()),
  operatingDays: [],
  lat: 0,
  lng: 0,
  accountNo: "",
  ifscCode: "",
  accountHolderName: "",
};

function ShopRegister() {
  const [shopImg, setShopImg] = useState("");
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState("");
  const [errmsg, setErrMsg] = useState("");
  const [location, setLocation] = useState({ lat: null, lng: null });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const days = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (error === name) setError("");

    setFormData((prev) => ({
      ...prev,
      [name]:
        name === "phone"
          ? Number(value)
          : typeof value === "function"
          ? value(prev[name])
          : value,
    }));
  };

  useEffect(() => {
    const fetchLocation = async () => {
      const location = await getLocation();
      setLocation({
        lat: location.latitude,
        lng: location.longitude,
      });
    };

    fetchLocation();
  }, []);

  // DAY SELECTION FUNCTION
  const handleSelectDay = (day) => {
    const e = {
      target: {
        name: "operatingDays",
        value: (prevState) => {
          if (prevState.includes(day)) {
            return prevState.filter((d) => d !== day);
          } else {
            return [...prevState, day];
          }
        },
      },
    };
    handleInputChange(e);
  };

  // FORM VALIDATION FUNCTIONS
  function validateContactInfo(phone, email) {
    const isPhoneValid = /^\d{10}$/.test(phone);
    const isEmailValid = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email);

    return {
      phoneValid: isPhoneValid,
      emailValid: isEmailValid,
    };
  }

  const validateLatitude = (lat) => {
    const latitude = parseFloat(lat);
    return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
  };

  const validateLongitude = (lng) => {
    const longitude = parseFloat(lng);
    return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.shopImg) {
      setError("shopImg");
      setErrMsg("Image is required.");
      return;
    }

    if (!formData.shopName) {
      setError("shopName");
      setErrMsg("Shop Name is required.");
      return;
    }

    if (!formData.email) {
      setError("email");
      setErrMsg("Email is required.");
      return;
    }

    if (!formData.phone) {
      setError("phone");
      setErrMsg("Contact Number is required.");
      return;
    }

    const contactInfo = validateContactInfo(
      formData.phone.toString(),
      formData.email
    );

    if (!contactInfo.emailValid) {
      setError("email");
      setErrMsg("Invalid Email.");
      return;
    }

    if (!contactInfo.phoneValid) {
      setError("phone");
      setErrMsg("Contact Number must be of 10 digits.");
      return;
    }

    if (!formData.shopAddress) {
      setError("shopAddress");
      setErrMsg("Shop Address is required.");
      return;
    }

    if (!formData.shopDetail) {
      setError("shopDetail");
      setErrMsg("Shop Detail is required.");
      return;
    }

    if (!formData.type) {
      setError("type");
      setErrMsg("Shop Type is required.");
      return;
    }

    if (!formData.category) {
      setError("category");
      setErrMsg("Shop Category is required.");
      return;
    }

    if (!formData.fromTime.toLocaleString()) {
      setError("fromTime");
      setErrMsg("Opening Time is required.");
      return;
    }

    if (!formData.toTime.toLocaleString()) {
      setError("toTime");
      setErrMsg("Closing Time is required.");
      return;
    }

    if (formData.operatingDays.length === 0) {
      setError("operatingDays");
      setErrMsg("Select at least one opening day");
      return;
    }

    if (!formData.lat) {
      setError("lat");
      setErrMsg("Latitude is required to get the exact location of shop.");
      return;
    }

    if (!formData.lng) {
      setError("lng");
      setErrMsg("Longitude is required to get the exact location of shop.");
      return;
    }

    // Validate latitude and longitude
    if (!validateLatitude(formData.lat.toString())) {
      setError("lat");
      setErrMsg("Invalid Latitude.");
      return;
    }

    if (!validateLongitude(formData.lng.toString())) {
      setError("lng");
      setErrMsg("Invalid Longitude.");
      return;
    }

    if (!formData.accountNo) {
      setError("accountNo");
      setErrMsg("Account Number is required.");
      return;
    }

    if (!formData.accountHolderName) {
      setError("accountHolderName");
      setErrMsg("Account Holder Name is required.");
      return;
    }

    if (!formData.ifscCode) {
      setError("ifscCode");
      setErrMsg("IFSC Code is required.");
      return;
    }

    try {
      const response = await axiosClient.post("shop/", formData);
      const shopId = response.result.id;
      setFormData(initialFormData);
      setShopImg("");
      dispatch(getShopAdmin(shopId));
      dispatch(getMyInfo());
      const params =
        formData.category === "grocery" ? "/add-products" : "/shop-feature";
      navigate(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.readyState === fileReader.DONE) {
        setShopImg(fileReader.result);
        const e = {
          target: {
            name: "shopImg",
            value: fileReader.result,
          },
        };
        handleInputChange(e);
      }
    };
  };

  const inputStyle =
    "input-style border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500";
  const label = "text-xs sm:text-sm font-medium mb-1";
  const imgLabel =
    "flex justify-center items-center border rounded-xl w-full h-52 text-blue-900 text-2xl border-blue-900 border-dashed";
  const input =
    "border-2 rounded-full px-2 text-sm py-[1px] w-full focus:outline-none";

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-lg">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Shop Registration
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* IMAGE */}
          <div className="flex justify-center mb-5">
            {shopImg ? (
              <div className="w-full">
                <img
                  src={shopImg}
                  alt="userImg"
                  className="border-2 border-green-500 border-dashed rounded-xl w-full h-52"
                />
              </div>
            ) : (
              <div
                className={`${imgLabel} ${
                  error === "shopImg" && "border-red-500 text-red-500"
                }`}
              >
                <label htmlFor="inputImg">
                  <BiSolidCameraPlus className="cursor-pointer" />
                </label>
                <input
                  className={`${input} hidden`}
                  id="inputImg"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            )}
          </div>
          {error === "shopImg" && (
            <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
          )}

          <div className="flex flex-col md:flex-row gap-5">
            <div>
              <p className={`${label}`}>NAME</p>
              <input
                type="text"
                name="shopName"
                value={formData.shopName}
                placeholder="Shop Name"
                className={`${inputStyle} ${
                  error === "shopName" && "border-[#FF4D4D]"
                }`}
                onChange={handleInputChange}
              />
              {error === "shopName" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>

            <div>
              <p className={`${label}`}>EMAIL</p>
              <input
                type="email"
                name="email"
                value={formData.email}
                placeholder="Email"
                className={`${inputStyle} ${
                  error === "email" && "border-[#FF4D4D]"
                }`}
                onChange={handleInputChange}
              />
              {error === "email" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-5">
            <div>
              <p className={`${label}`}>CONTACT NUMBER</p>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                placeholder="Contact Number"
                className={`${inputStyle} ${
                  error === "phone" && "border-[#FF4D4D]"
                }`}
                onChange={handleInputChange}
              />
              {error === "phone" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>

            <div>
              <p className={`${label}`}>SHOP ADDRESS</p>
              <input
                type="text"
                name="shopAddress"
                value={formData.shopAddress}
                placeholder="Shop Address"
                className={`${inputStyle} ${
                  error === "shopAddress" && "border-[#FF4D4D]"
                }`}
                onChange={handleInputChange}
              />
              {error === "shopAddress" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>
          </div>

          <p className={`${label}`}>SHOP DETAIL</p>
          <input
            type="text"
            name="shopDetail"
            value={formData.shopDetail}
            placeholder="Shop Detail"
            className={`${inputStyle} ${
              error === "shopDetail" && "border-[#FF4D4D]"
            }`}
            onChange={handleInputChange}
          />
          {error === "shopDetail" && (
            <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
          )}

          <div className="flex flex-col md:flex-row gap-5">
            <div>
              <p className={`${label}`}>SHOP TYPE</p>
              <select
                name="type"
                className={`${inputStyle} ${
                  error === "type" && "border-[#FF4D4D]"
                }`}
                onChange={handleInputChange}
              >
                <option value="">Select Shop Type</option>
                <option value="appointment">Appointment</option>
                <option value="order">Order</option>
              </select>
              {error === "type" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>

            <div>
              <p className={`${label}`}>SHOP CATEGORY</p>
              <select
                name="category"
                className={`${inputStyle} ${
                  error === "category" && "border-[#FF4D4D]"
                }`}
                onChange={handleInputChange}
              >
                <option value="">Select Shop Category</option>
                <option value="barber">Barber</option>
                <option value="grocery">Grocery</option>
              </select>
              {error === "category" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>
          </div>

          {/* TIME PICKERS */}
          <p className={`${label}`}>OPENING HOURS</p>
          <div className="flex space-x-5">
            <div>
              <DatePicker
                selected={
                  formData.fromTime
                    ? new Date(`1970-01-01T${formData.fromTime}`)
                    : null
                }
                onChange={(date) => {
                  const e = {
                    target: {
                      name: "fromTime",
                      value: extractTimeFromTimestamp(date),
                    },
                  };
                  handleInputChange(e);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className={`${inputStyle} ${
                  error === "fromTime" && "border-[#FF4D4D]"
                }`}
              />
              {error === "fromTime" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>
            <div>
              <DatePicker
                selected={
                  formData.toTime
                    ? new Date(`1970-01-01T${formData.toTime}`)
                    : null
                }
                onChange={(date) => {
                  const e = {
                    target: {
                      name: "toTime",
                      value: extractTimeFromTimestamp(date),
                    },
                  };
                  handleInputChange(e);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className={`${inputStyle} ${
                  error === "toTime" && "border-[#FF4D4D]"
                }`}
              />
              {error === "toTime" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>
          </div>

          {/* OPERATING DAYS */}
          <p className={`${label}`}>OPERATING DAYS</p>
          <div className="flex justify-center space-x-2">
            {days.map((day, index) => (
              <p
                key={index}
                className={`p-2 border rounded cursor-pointer ${
                  formData.operatingDays.includes(day)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100"
                }`}
                onClick={() => {
                  handleSelectDay(day);
                  if (error === "operatingDays") setError("");
                }}
              >
                {day}
              </p>
            ))}
          </div>
          {error === "operatingDays" && (
            <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
          )}

          {/* LOCATION */}
          <p className={`${label}`}>SHOP LOCATION</p>
          <div className="flex space-x-5">
            <div>
              <input
                name="lat"
                type="text"
                placeholder="Latitude"
                className={`${inputStyle} ${
                  error === "lat" && "border-[#FF4D4D]"
                }`}
                value={formData.lat}
                onChange={handleInputChange}
              />
              {error === "lat" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>
            <div>
              <input
                name="lng"
                type="text"
                placeholder="Longitude"
                className={`${inputStyle} ${
                  error === "lng" && "border-[#FF4D4D]"
                }`}
                value={formData.lng}
                onChange={handleInputChange}
              />
              {error === "lng" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>
          </div>

          <div className="flex justify-center">
            <p
              className="flex items-center gap-2 text-white bg-[#0099FF] rounded py-2 px-4 cursor-pointer"
              onClick={() => {
                const lat = {
                  target: {
                    name: "lat",
                    value: location.lat,
                  },
                };

                const lng = {
                  target: {
                    name: "lng",
                    value: location.lng,
                  },
                };
                handleInputChange(lat);
                handleInputChange(lng);
              }}
            >
              <MdMyLocation />
              SET CURRENT LOCATION
            </p>
          </div>

          {/* BANKING DETAILS */}

          <div>
            <p className={`${label}`}>ACCOUNT NUMBER</p>
            <input
              type="text"
              name="accountNo"
              value={formData.accountNo}
              placeholder="Account Number"
              className={`${inputStyle} ${
                error === "accountNo" && "border-[#FF4D4D]"
              }`}
              onChange={handleInputChange}
            />
            {error === "accountNo" && (
              <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
            )}
          </div>

          <div className="flex flex-col md:flex-row gap-5">
            <div>
              <p className={`${label}`}>IFSC CODE</p>
              <input
                type="text"
                name="ifscCode"
                value={formData.ifscCode}
                placeholder="IFSC Code"
                className={`${inputStyle} ${
                  error === "ifscCode" && "border-[#FF4D4D]"
                }`}
                onChange={handleInputChange}
              />
              {error === "ifscCode" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>

            <div>
              <p className={`${label}`}>ACCOUNT HOLDER NAME</p>
              <input
                type="text"
                name="accountHolderName"
                value={formData.accountHolderName}
                placeholder="Account Holder Name"
                className={`${inputStyle} ${
                  error === "accountHolderName" && "border-[#FF4D4D]"
                }`}
                onChange={handleInputChange}
              />
              {error === "accountHolderName" && (
                <p className="text-[#FF4D4D] mb-4">{errmsg}</p>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 transition"
          >
            Register
          </button>
        </form>
      </div>
    </div>
  );
}

export default ShopRegister;
