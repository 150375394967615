import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomerReviews from "../../components/CustomerReviews";
import { isShopOpen } from "../../utils/shopStatus";
import { axiosClient } from "../../utils/axiosClient";
import {
  IoCheckmarkCircle,
  IoCloseCircle,
  IoListOutline,
} from "react-icons/io5";
import { FaAngleRight } from "react-icons/fa6";
import { LiaStoreAltSolid } from "react-icons/lia";
import Sidebar from "../../components/Sidebar";
import { MdCalendarMonth, MdMiscellaneousServices } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import { ImClipboard } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { getShopAdmin } from "../../redux/slices/shopsSlice";

const ShopAdmin = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [activeTab, setActiveTab] = useState("Products");
  const sectionRefs = useRef({
    Services: null,
    About: null,
  });

  const sections = [
    { id: "Products", title: "Products" },
    { id: "About", title: "About" },
  ];

  const shopData = useSelector((state) => state?.shopsSlice?.shopsData);
  const shopId = useSelector((state) => state?.shopsSlice?.shopId);

  const sidebarLinks = [
    {
      icon: <MdCalendarMonth />,
      title: "Manage Orders",
      to: `/shop-orders/${shopId}`,
    },
    {
      icon: <MdMiscellaneousServices />,
      title: "Manage Services",
      to: "/add-products",
    },
    {
      icon: <ImClipboard />,
      title: "Overview",
      to: "",
    },
    {
      icon: <BsFillBarChartFill />,
      title: "Reports & Analytics",
      to: "",
    },
  ];

  useEffect(() => {
    dispatch(getShopAdmin(id));
  }, [dispatch, id]);

  const scrollToSection = (id) => {
    const section = sectionRefs.current[id];
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setActiveTab(id);
  };

  const dayMap = {
    Sunday: "Su",
    Monday: "M",
    Tuesday: "Tu",
    Wednesday: "W",
    Thursday: "Th",
    Friday: "F",
    Saturday: "Sa",
  };

  // Reverse dayMap to get full names from short names
  const reverseDayMap = Object.fromEntries(
    Object.entries(dayMap)?.map(([full, short]) => [short, full])
  );

  // Get today's short name (e.g., "M")
  const today = new Date().toLocaleString("en-US", { weekday: "long" });
  const currentDay = dayMap[today]; // e.g., "M"

  // Example shop operating days
  const operatingDays = ["Su", "M", "W", "F", "Sa"]; // Replace with shopData.operatingDays

  // Map days to indices for easy comparison
  const dayOrder = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];
  const todayIndex = dayOrder.indexOf(currentDay);

  // Find the next available day
  let day;

  // Loop through operatingDays to find the next available day
  for (let i = 1; i <= 7; i++) {
    const nextDayIndex = (todayIndex + i) % 7; // Circular increment
    const nextDayShort = dayOrder[nextDayIndex]; // Short name like "W"
    if (operatingDays.includes(nextDayShort)) {
      day = reverseDayMap[nextDayShort]; // Convert short name to full name
      break;
    }
  }

  const operatingHours = shopData?.operatingHours || "";
  const [opens, closes] = operatingHours
    .split(" - ")
    .map((time) => time.trim());

  return (
    <div className="flex flex-col bg-white -mt-2">
      <div className="flex flex-col md:flex-row md:items-start md:justify-around md:py-4">
        {/* Fixed Header */}
        <div className="w-full h-64 md:size-80 lg:size-96 bg-gray-200 flex justify-center items-center">
          {shopData?.imgUrl?.url && (
            <img
              src={shopData.imgUrl.url}
              alt="Shop"
              className="w-full h-full object-cover"
            />
          )}
        </div>

        {/* Fixed Shop Details */}
        <div className="p-4 md:p-0">
          <h1 className="text-lg md:text-2xl font-semibold">
            {shopData?.shopName}
          </h1>
          <p className="text-sm md:text-base text-gray-600">
            {shopData?.shopAddress}
          </p>

          <div className="flex flex-col space-y-2 mt-2 text-sm md:text-base">
            <div className="flex items-center md:flex-col md:items-start space-x-2 md:space-x-0 md:space-y-2">
              {isShopOpen(shopData?.operatingDays, shopData?.operatingHours) ? (
                <span className="flex items-center gap-1 self-start p-1 bg-[#e0e0e0] rounded">
                  <span className="text-green-500">
                    <IoCheckmarkCircle />
                  </span>
                  Open now
                </span>
              ) : (
                <span className="flex items-center gap-1 self-start p-1 bg-[#e0e0e0] rounded">
                  <span className="text-red-500">
                    <IoCloseCircle />
                  </span>
                  Closed now
                </span>
              )}
              <span className="p-1 bg-[#e0e0e0] rounded">
                {isShopOpen(shopData?.operatingDays, shopData?.operatingHours)
                  ? `Closes at ${closes}`
                  : `Opens on ${day} at ${opens}`}
              </span>
            </div>
            <div className="flex items-center md:flex-col md:items-start space-x-2 md:space-x-0 md:space-y-2">
              <button
                className="flex items-center self-start gap-1 p-1 bg-[#e0e0e0] rounded"
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                <LiaStoreAltSolid />
                <span>Manage</span>
                <FaAngleRight />
              </button>

              <button
                className="flex items-center self-start gap-1 p-1 bg-[#e0e0e0] rounded"
                onClick={() => navigate(`/shop-orders/${shopId}`)}
              >
                <IoListOutline />
                <span>Orders</span>
                <FaAngleRight />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Fixed Tabs */}
      <div className="flex justify-around bg-gray-100 border-b shadow-md sticky top-0 z-10">
        {sections?.map((section) => (
          <button
            key={section.id}
            onClick={() => scrollToSection(section.id)}
            className={`p-2 text-sm font-semibold border-b-2 ${
              activeTab === section.id
                ? "border-blue-500 text-blue-500"
                : "border-transparent text-gray-600"
            }`}
          >
            {section.title}
          </button>
        ))}
      </div>

      {/* Scrollable Content */}
      <div className="flex-1">
        {/* Services Section */}
        <div
          id="Services"
          ref={(el) => (sectionRefs.current.Services = el)}
          className="p-4"
        >
          <h2 className="text-lg font-semibold mb-4">Services</h2>
          <div className="grid grid-cols-4 gap-4">
            {shopData?.products?.map((items) => (
              <div key={items._id} className="text-center">
                <img
                  src={items.productId.imgUrl.url}
                  alt={items.productId.name}
                  className="size-20 sm:size-40 md:size-56 object-cover mx-auto rounded-md"
                />
                <h3 className="text-sm font-medium mt-2">
                  {items.productId.name}
                </h3>
                <p className="text-sm text-gray-600">
                  ₹ {items.variants[0].price}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* About Section */}
        <div
          id="About"
          ref={(el) => (sectionRefs.current.About = el)}
          className="p-4"
        >
          <h2 className="text-lg font-semibold mb-2">About</h2>
          <p className="text-gray-600">{shopData?.shopDetail}</p>

          <h2 className="text-lg font-semibold mt-4">Address</h2>
          <p className="text-gray-600">{shopData?.shopAddress}</p>
        </div>
      </div>

      {openSidebar && (
        <Sidebar
          from="Manage"
          links={sidebarLinks}
          onClose={() => setOpenSidebar(false)}
        />
      )}
    </div>
  );
};

export default ShopAdmin;
