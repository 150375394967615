import React from "react";
import { FaUser, FaStar } from "react-icons/fa";
import { formatDate } from "../utils/formatDate";

const CustomerReviews = ({ review }) => {
  return (
    <div className="my-2 mx-4 p-3 space-y-2 border rounded-md border-gray-300">
      {/* Customer Name */}
      <div className="flex items-center space-x-2">
        <FaUser className="text-black text-sm" />
        <p className="text-sm font-medium">{review.userName}</p>
      </div>

      {/* Rating */}
      <div className="flex items-center space-x-1">
        {Array.from({ length: 5 }, (_, index) => (
          <FaStar
            key={index}
            className={`text-sm ${
              index < review.rating ? "text-yellow-500" : "text-gray-300"
            }`}
          />
        ))}
      </div>

      {/* Visit Date */}
      <p className="text-xs text-gray-500">
        Visited on {formatDate(review.date)}
      </p>
    </div>
  );
};

export default CustomerReviews;
