import React, { useEffect, useState } from "react";
import { axiosClient } from "../../utils/axiosClient";
import { useLocation } from "react-router-dom";
import ProductCard from "../../components/grocyGoComponents/ProductCard";

function Products() {
  const [index, setIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState("lowToHigh");
  const Location = useLocation();
  const { categoryId } = Location.state || {};

  const handlePress = (idx) => {
    setIndex(idx);
  };

  const getSortedProducts = () => {
    const sortedProducts = [...(products[index]?.products || [])];

    return sortedProducts.sort((a, b) => {
      const priceA = a.variants?.length
        ? Math.min(...a.variants.map((v) => v.averagePrice))
        : Infinity;

      const priceB = b.variants?.length
        ? Math.min(...b.variants.map((v) => v.averagePrice))
        : Infinity;

      return sortOrder === "lowToHigh" ? priceA - priceB : priceB - priceA;
    });
  };

  useEffect(() => {
    const getProducts = async () => {
      const params = `/grocery/average-price?latitude= 29.6914121&longitude=77.2441538&id=${categoryId}`;

      const res = await axiosClient.get(params);
      setProducts(res.result);
    };
    getProducts();
  }, []);

  return (
    <div className="flex bg-white">
      {/* Left Section - Product Categories */}
      <div className="flex-1 bg-[#f5f5f5] px-4 pb-4">
        <div className="flex flex-col gap-5 h-screen overflow-y-auto hideScrollbar">
          {products.map((item, idx) => (
            <div
              key={item._id || idx}
              onClick={() => handlePress(idx)}
              className="flex flex-col items-center cursor-pointer"
            >
              <img
                src={item.imgUrl}
                alt={item.name}
                className="size-20 rounded-full"
              />
              <p
                className={`text-center pt-4 text-xs sm:text-sm ${
                  index === idx ? "font-semibold" : ""
                }`}
              >
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Right Section - Product Details */}
      <div className="flex-4 bg-[#fff] p-4 sm:-mt-2 h-screen overflow-y-auto hideScrollbar">
        <div className="flex justify-center sm:justify-around gap-4 sm:mx-10 mb-4 text-xs">
          <div
            onClick={() => setSortOrder("lowToHigh")}
            className={`cursor-pointer p-2 sm:px-6 sm:py-3 rounded-lg border ${
              sortOrder === "lowToHigh"
                ? "bg-green-500 text-white"
                : "bg-[#ddd] text-black"
            }`}
          >
            <p>Price: Low to High</p>
          </div>
          <div
            onClick={() => setSortOrder("highToLow")}
            className={`cursor-pointer p-2 sm:px-6 sm:py-3 rounded-lg border ${
              sortOrder === "highToLow"
                ? "bg-green-500 text-white"
                : "bg-[#ddd] text-black"
            }`}
          >
            <p>Price: High to Low</p>
          </div>
        </div>

        <ProductCard products={getSortedProducts()} />
      </div>
    </div>
  );
}

export default Products;
