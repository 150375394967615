import React, { useEffect, useState } from "react";
import QuantitySelector from "./QuantitySelector";
import CartButton from "./CartButton";

function ProductCard({ products }) {
  const [selectedQuantities, setSelectedQuantities] = useState({});

  useEffect(() => {
    const initialQuantities = {};

    products.forEach((product) => {
      if (product.variants.length > 0) {
        initialQuantities[product._id] = product.variants[0];
      }
    });

    setSelectedQuantities(initialQuantities);
  }, [products]);

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
      {products.map((item) => (
        <div
          key={item._id}
          className="p-2 border rounded-lg space-y-2 shadow-md"
        >
          <img
            src={item.imgUrl.url}
            alt={item.name}
            className="w-full h-40 sm:h-60 rounded-lg"
            loading="lazy"
          />

          <div className="flex justify-between items-center text-[10px] sm:text-xs">
            <p className="mt-2">{item.name}</p>
            <CartButton item={item} selectedQuantities={selectedQuantities} />
          </div>

          <div className="flex justify-between items-center">
            <p className="text-xs sm:text-base">
              ₹{" "}
              {selectedQuantities[item._id]?.averagePrice ||
                item.variants[0]?.averagePrice}{" "}
              <span className="text-[#999] text-[8px]">(Avg. Price)</span>
            </p>

            <QuantitySelector
              item={item}
              selectedQuantities={selectedQuantities}
              setSelectedQuantities={setSelectedQuantities}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductCard;
