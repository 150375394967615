import React, { useEffect, useState } from "react";
import { axiosClient } from "../../utils/axiosClient";
import SearchBar from "../../components/globalComponents/SearchBar";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

const productNames = [
  "Oils",
  "Snacks",
  "Spices",
  "Vegetables",
  "Dairy",
  "Atta",
  "Rice",
  "Dal",
];

function AddShopProducts() {
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  const handlePress = (idx) => {
    setIndex(idx);
  };

  const toggleSelection = (product) => {
    setSelectedItems((prevItems) => {
      const alreadySelected = prevItems.some((item) => item.id === product.id);
      return alreadySelected
        ? prevItems.filter((item) => item.id !== product.id)
        : [...prevItems, product];
    });
  };

  const getProducts = () => {
    const category = data?.find((item) => item.categoryId === categoryId);
    const subCategory = category?.subCategories.find((_, idx) => idx === index);

    return subCategory?.products || [];
  };

  useEffect(() => {
    const getProducts = async () => {
      const res = await axiosClient.get("/grocery/allProducts");
      setData(res.result);
      setCategoryId(res.result[0].categoryId);
    };
    getProducts();
  }, []);

  useEffect(() => {
    const allProducts = getProducts();
    if (searchQuery.trim() === "") {
      setFilteredProducts(allProducts);
    } else {
      const filtered = allProducts?.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  }, [searchQuery, data, categoryId, index]);

  const handleNavigation = () => {
    if (selectedItems.length === 0) {
      toast.error("Select at least one product");
      return;
    }

    navigate("/add-price", { state: { selectedItems } });
  };

  return (
    <div className="mt-2 sm:mt-0">
      <div className="flex items-center justify-around sm:justify-center">
        {/* Empty div for spacing on larger screens */}
        <div className="flex-1 hidden sm:block"></div>

        {/* Search Bar in the center */}
        <div className="flex-1 flex justify-center ml-5 sm:ml-0">
          <SearchBar
            productNames={productNames}
            iconColor="green"
            borderColor="green"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>

        {/* "NEXT" button with spacing */}
        <div className="flex-1 flex justify-center text-sm">
          <button
            onClick={handleNavigation}
            className="flex items-center gap-2 bg-green-500 text-white border border-green-500 hover:bg-transparent hover:text-green-500 px-2 py-1 sm:px-6 sm:py-2 rounded"
          >
            <p>NEXT</p>
            <AiOutlineArrowRight />
          </button>
        </div>
      </div>

      <div className="flex sm:justify-center gap-5 overflow-x-auto whitespace-nowrap my-5 px-5 scroll-smooth hideScrollbar">
        {data.map((item) => (
          <div
            key={item.categoryId}
            className="flex flex-col items-center gap-2 cursor-pointer min-w-[80px] scroll-snap-start"
            onClick={() => setCategoryId(item.categoryId)}
          >
            <img
              src={item.categoryImg}
              alt={item.categoryName}
              className="size-20 rounded-full"
            />
            <p
              className={`text-xs ${
                categoryId === item.categoryId ? "font-semibold" : ""
              }`}
            >
              {item.categoryName}
            </p>
          </div>
        ))}
      </div>

      <div className="flex bg-white">
        {/* Left Section - Product Categories */}
        <div className="flex-1 bg-[#f5f5f5] px-4 pb-4">
          <div className="flex flex-col gap-5 h-screen overflow-y-auto hideScrollbar">
            {data
              ?.find((item) => item.categoryId === categoryId)
              ?.subCategories.map((item, idx) => (
                <div
                  key={item._id || idx}
                  onClick={() => handlePress(idx)}
                  className="flex flex-col items-center cursor-pointer"
                >
                  <img
                    src={item.img}
                    alt={item.name}
                    className="size-16 sm:size-18 rounded-full"
                  />
                  <p
                    className={`text-center pt-4 text-xs sm:text-sm ${
                      index === idx ? "font-semibold" : ""
                    }`}
                  >
                    {item.name}
                  </p>
                </div>
              ))}
          </div>
        </div>

        {/* Right Section - Product Details */}
        <div className="flex-4 bg-[#fff] p-4 sm:-mt-2 h-screen overflow-y-auto hideScrollbar">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {filteredProducts.map((item) => {
              const isSelected = selectedItems.some(
                (product) => product.id === item.id
              );

              return (
                <div
                  key={item._id}
                  className={`p-2 border-2 rounded-lg shadow-md cursor-pointer ${
                    isSelected ? "border-green-500" : "border-gray-300"
                  }`}
                  onClick={() => {
                    toggleSelection(item);
                  }}
                >
                  <img
                    src={item.img}
                    alt={item.name}
                    className="w-full h-40 sm:h-60 rounded-lg"
                  />
                  <p className="mt-2 text-[10px] sm:text-xs text-center">
                    {item.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddShopProducts;
