import React from "react";
import { useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/slices/groceryCartSlice";

function CartItem({ item }) {
  const dispatch = useDispatch();

  return (
    <div className="flex items-center gap-2 p-4 bg-white m-1 border rounded-lg shadow-md">
      <div className="size-20">
        <img
          src={item.imgUrl.url}
          alt={item.name}
          className="w-full h-full object-contain rounded"
        />
      </div>

      <div className="text-xs">
        <div className="flex justify-between">
          <p className="font-medium text-[#333] w-[120px] sm:w-[160px]">
            {item.name}
          </p>
          <p className="text-[#777]">{item.unit}</p>
        </div>

        <div className="flex justify-between items-center">
          <div>
            <p className="text-[#555] mt-5">₹ {item.price}</p>
            <p className="font-semibold text-[#222] mt-2">
              Subtotal: ₹ {item.price * item.quantity}
            </p>
          </div>

          <div className="text-white w-fit bg-[#607d8b] rounded">
            <span
              className="p-3 cursor-pointer font-medium"
              onClick={() => dispatch(removeFromCart(item._id))}
            >
              -
            </span>
            <span className="font-medium">{item.quantity}</span>
            <span
              className="p-3 cursor-pointer font-medium"
              onClick={() =>
                dispatch(
                  addToCart({
                    imgUrl: item.imgUrl,
                    _id: item._id,
                    name: item.name,
                    price: item.price,
                    unit: item.unit,
                    quantity: item.quantity,
                  })
                )
              }
            >
              +
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
