import React from "react";
import { useNavigate } from "react-router-dom";
import { isShopOpen } from "../utils/shopStatus";
import DistanceCalculator from "./DistanceCalculator";

function ShopCard({ data }) {
  const navigate = useNavigate();

  const onNavigate = () => {
    navigate(`/shop/${data.shopId}`);
  };
  return (
    <div
      className="flex flex-col gap-2 mx-4 my-2 rounded-md bg-white shadow-md hover:shadow-lg transition-shadow cursor-pointer"
      onClick={onNavigate}
    >
      <img
        src={data.shopImg}
        alt={data.shopName}
        className="w-full h-48 object-cover rounded-md"
      />
      <div className="p-4 flex flex-col gap-2">
        {/* Shop Name and Open/Close Status */}
        <div className="flex justify-between items-center">
          <h2 className="font-semibold text-lg">{data.shopName}</h2>
          <span
            className={`px-2 py-1 text-sm font-semibold rounded ${
              isShopOpen(data.operatingDays, data.operatingHours)
                ? "text-green-600"
                : "text-red-600"
            }`}
          >
            {isShopOpen(data.operatingDays, data.operatingHours)
              ? "Open"
              : "Close"}
          </span>
        </div>

        {/* Address and Distance */}
        <p className="text-gray-600 text-sm">
          {data.shopAddress} |{" "}
          <DistanceCalculator destinationCoords={data.location.coordinates} />
        </p>

        {/* Operating Days and Hours */}
        <div className="flex flex-wrap items-center text-gray-600 text-sm">
          <div className="flex gap-2">
            {data.operatingDays.map((day) => (
              <span key={day} className="">
                {day}
              </span>
            ))}
          </div>
          <span className="ml-2">| {data.operatingHours}</span>
        </div>
      </div>
    </div>
  );
}

export default ShopCard;
