import React, { useEffect, useState } from "react";
import moment from "moment";

const AppointmentTiming = ({ onDateChange, onTimeChange }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dates, setDates] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);

  const shopOpeningHour = 8;
  const shopClosingHour = 20;

  useEffect(() => {
    generateDates();
    generateTimeSlots(new Date());
  }, []);

  const generateDates = () => {
    const days = [];
    for (let i = 0; i < 30; i++) {
      days.push(moment().add(i, "days").toDate());
    }
    setDates(days);
  };

  const generateTimeSlots = (date) => {
    const slots = [];
    const now = moment();
    const bufferTime = moment().add(15, "minutes");
    const selectedDay = moment(date).startOf("day");

    for (let hour = shopOpeningHour; hour < shopClosingHour; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const time = moment(selectedDay).hour(hour).minute(minutes).second(0);
        if (
          moment(date).isSame(new Date(), "day") &&
          time.isBefore(bufferTime)
        ) {
          continue;
        }
        slots.push({
          label: time.format("hh:mm A"),
          value: time.format("HH:mm"),
        });
      }
    }

    const groupedSlots = [];
    for (let i = 0; i < slots.length; i += 4) {
      groupedSlots.push(slots.slice(i, i + 4));
    }
    setTimeSlots(groupedSlots);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    onDateChange(date);
    generateTimeSlots(date);
    setSelectedTime(null);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    onTimeChange(time);
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-lg font-bold mb-4">
        Select Date & Time of Appointment
      </h2>

      {/* Date Selection */}
      <div className="flex overflow-x-auto space-x-2 pb-2">
        {dates.map((item, index) => (
          <button
            key={index}
            onClick={() => handleDateSelect(item)}
            className={`flex flex-col items-center p-3 border rounded-lg min-w-[60px] transition-all duration-300 ${
              moment(item).isSame(selectedDate, "day")
                ? "bg-blue-500 text-white"
                : "bg-gray-100 text-gray-700"
            }`}
          >
            <span className="text-sm font-semibold">
              {moment(item).format("ddd").toUpperCase()}
            </span>
            <span className="text-xl font-bold">
              {moment(item).format("D")}
            </span>
            <span className="text-xs">{moment(item).format("MMM")}</span>
          </button>
        ))}
      </div>

      <h3 className="text-md font-semibold mt-4">Available Time Slots</h3>

      {/* Time Slots */}
      <div className="overflow-x-auto pb-3">
        <div className="flex space-x-4 w-max">
          {timeSlots.length > 0 ? (
            timeSlots.map((group, index) => (
              <div key={index} className="flex flex-col space-y-3">
                {group.map((slot) => (
                  <button
                    key={slot.value}
                    onClick={() => handleTimeSelect(slot.value)}
                    className={`p-2 border rounded-lg transition-all duration-300 ${
                      selectedTime === slot.value
                        ? "bg-blue-500 text-white"
                        : "bg-gray-100 text-gray-700"
                    }`}
                  >
                    {slot.label}
                  </button>
                ))}
              </div>
            ))
          ) : (
            <p className="text-gray-500 col-span-full">No slots available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentTiming;
