import React from "react";
import { IoCalendarOutline } from "react-icons/io5";

const DiscountCard = ({ onClick }) => {
  return (
    <div className="w-full bg-gradient-to-r from-[#1B1B1B] to-yellow-400 rounded-3xl p-6 mt-6 shadow-xl mx-auto relative overflow-hidden">
      {/* Subtle glowing effect */}
      <div className="absolute inset-0 bg-white/10 backdrop-blur-md rounded-3xl"></div>

      {/* Content */}
      <div className="relative z-10 text-center">
        <p className="text-lg font-semibold text-white tracking-wide">
          🔥 Book & Pay Now - Save 10%!
        </p>
        <p className="text-3xl font-extrabold text-yellow-200 my-3 drop-shadow-md">
          Get 10% OFF 💰
        </p>
        <p className="text-base text-white opacity-90 px-3">
          Book your appointment now and pay instantly to unlock savings on your service!
        </p>

        {/* Button with hover effect */}
        <button
          onClick={onClick}
          className="bg-white rounded-full py-3 px-6 flex items-center justify-center w-full mt-5 transition-all duration-300 hover:scale-105 hover:shadow-lg active:scale-95"
        >
          <IoCalendarOutline size={22} className="text-yellow-500" />
          <span className="text-lg font-bold text-yellow-500 ml-3">
            Book & Pay Now
          </span>
        </button>
      </div>
    </div>
  );
};

export default DiscountCard;
