import React, { useEffect, useState } from "react";
import { getLocation } from "../../utils/getLocation";
import ProductCard from "../../components/grocyGoComponents/ProductCard";
import { axiosClient } from "../../utils/axiosClient";

function SeasonalProducts() {
  let season = "Summer";
  const [products, setProducts] = useState([]);

  useEffect(() => {
    try {
      const getProducts = async () => {
        const location = await getLocation();
        if (location) {
          const response = await axiosClient.get(
            `/grocery/seasons?latitude=${location?.latitude}&longitude=${location?.longitude}&season=${season}`
          );
          setProducts(response.result);
        }
      };
      getProducts();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="mx-5 sm:mx-10 mb-10 mt-5">
      <p className="text-[#2f2f2f] text-center mb-4 font-semibold text-lg">
        Seasonal Products
      </p>
      <ProductCard products={products} />
    </div>
  );
}

export default SeasonalProducts;
