import React, { useEffect, useState } from "react";
import { axiosClient } from "../utils/axiosClient";
import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import Service from "../components/Service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  deleteFromCart,
  resetCart,
} from "../redux/slices/barberCart";
import toast from "react-hot-toast";
import { ImCancelCircle } from "react-icons/im";
import { getItem, setItem } from "../utils/localStorageManager";

function BarberCategory() {
  const [isFemale, setIsFemale] = useState(() => {
    return getItem("isFemale") === "true";
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [filteredServices, setFilteredServices] = useState([]);
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.barberCart.cart);

  useEffect(() => {
    setItem("isFemale", isFemale.toString());
    const api = async () => {
      try {
        const res = await axiosClient.get(
          `allServices/${isFemale ? "Female" : "Male"}`
        );
        setServices(res.result[0].services);
      } catch (error) {
        console.log("error", error);
      }
    };
    api();
  }, [isFemale]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredServices(services);
    } else {
      const filtered = services.filter((service) =>
        service.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredServices(filtered);
    }
  }, [searchQuery, services]);

  const toggleSwitch = () => {
    setIsFemale((previousState) => !previousState);
    dispatch(resetCart());
  };

  const handleToggleSelection = (item) => {
    const selected = cart?.find((i) => i.serviceId === item._id);
    if (selected) {
      dispatch(deleteFromCart(item._id));
    } else {
      dispatch(
        addToCart({
          serviceId: item._id,
          title: item.title,
          imgUrl: item.imgUrl,
          price: "0",
          _id: "",
        })
      );
    }
  };

  const handleRemoveItem = (id) => {
    dispatch(deleteFromCart(id));
  };

  const handleNavigate = () => {
    const items = cart.map((item) => item.title);
    const gender = isFemale ? "Female" : "Male";
    if (!cart || cart.length === 0) {
      toast.error("Please! select the services you want.");
    } else {
      navigate("/shops-list", { state: { items, gender } });
      toast.success("Selected services added to cart.");
    }
  };

  return (
    <div className="w-full flex flex-col items-center p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 text-center">
        Select Services
      </h2>
      {/* SEARCH BAR */}
      <div
        className={`flex items-center border rounded-lg px-3 py-2 w-full max-w-md mx-auto transition-colors
        ${
          isFocused
            ? isFemale
              ? "border-[#FFC1E3]"
              : "border-[#81b0ff]"
            : "border-gray-300"
        }
      `}
      >
        <AiOutlineSearch
          size={18}
          className={`${isFemale ? "text-pink-500" : "text-blue-400"}`}
        />
        <input
          type="text"
          className="w-full p-2 outline-none bg-transparent text-sm sm:text-base"
          placeholder={`Search for ${isFemale ? '"Facial"' : '"Beard Trim"'}`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>

      {/* TOGGLE GENDER */}
      <div className="mt-4 flex items-center space-x-3">
        <span
          className={`text-base md:text-lg font-semibold ${
            !isFemale ? "text-[#1E90FF]" : "text-[#555]"
          }`}
        >
          Male
        </span>
        <button
          className={`w-14 h-7 flex items-center rounded-full p-1 transition duration-300 ${
            isFemale ? "bg-[#FFC1E3]" : "bg-[#81b0ff]"
          }`}
          onClick={toggleSwitch}
        >
          <div
            className={`h-5 w-5 bg-white rounded-full shadow-md transform ${
              isFemale ? "translate-x-7" : "translate-x-0"
            } ${
              isFemale ? "bg-[#FF1493]" : "bg-[#1E90FF]"
            } transition duration-300`}
          ></div>
        </button>
        <span
          className={`text-base md:text-lg font-semibold ${
            isFemale ? "text-[#FF1493]" : "text-[#555]"
          }`}
        >
          Female
        </span>
      </div>

      {cart?.length > 0 && (
        <div className="border-b border-gray-300 flex justify-center">
          <div>
            <h3 className="font-medium text-sm my-2 text-center">
              Selected Items
            </h3>

            {/* Ensure images don't squeeze and have proper horizontal scroll */}
            <div className="flex md:justify-center space-x-4 w-96 overflow-x-auto hideScrollbar">
              {cart.map((item) => {
                const formattedTitle = item.title.replace(/\s*\(.*?\)\s*/g, "");

                return (
                  <div
                    key={item.serviceId}
                    className="flex flex-col items-center snap-center"
                  >
                    <div className="relative">
                      <img
                        src={item.imgUrl.url}
                        alt={formattedTitle}
                        className="rounded-md w-20 h-20 min-w-[5rem] object-cover"
                      />
                      <button
                        onClick={() => handleRemoveItem(item.serviceId)}
                        className={`absolute top-1 right-1 rounded-full p-1 z-10 text-white 
                    ${
                      isFemale ? "bg-pink-500" : "bg-blue-500"
                    } hover:scale-110 transition`}
                      >
                        <ImCancelCircle size={14} />
                      </button>
                    </div>
                    <p className="mt-1 text-xs text-center">{formattedTitle}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {services?.length > 0 && (
        <Service
          services={filteredServices}
          selectedItems={cart}
          onToggleSelection={handleToggleSelection}
          isFemale={isFemale}
        />
      )}

      <button
        className={`flex items-center justify-center border rounded-md px-4 py-2 text-lg font-medium w-fit mx-auto mb-3 transition-colors
        ${
          isFemale
            ? "text-pink-500 border-pink-500"
            : "text-blue-500 border-blue-500"
        }
      `}
        onClick={handleNavigate}
      >
        {isFemale ? "Find Parlour" : "Find Salon"}
        <AiOutlineArrowRight
          size={18}
          className={`ml-2 ${isFemale ? "text-pink-500" : "text-blue-500"}`}
        />
      </button>
    </div>
  );
}

export default BarberCategory;
