import React from "react";

const BookingDiscount = ({ originalPrice, discountPercent }) => {
  const discountedPrice = (
    originalPrice -
    (originalPrice * discountPercent) / 100
  ).toFixed(2);

  return (
    <div className="bg-gradient-to-r from-[#FF7E5F] to-[#FEB47B] rounded-xl p-4 mt-4 text-white text-center">
      <p className="text-lg font-bold">
        💰 Book & Pay Now - Save {discountPercent}%!
      </p>
      <p className="text-2xl font-bold">
        ₹{discountedPrice}{" "}
        <span className="line-through opacity-70 text-lg">
          ₹{originalPrice}
        </span>
      </p>
    </div>
  );
};

export default BookingDiscount;
