import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const ServiceCard = ({ service }) => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div
      className="relative w-full h-52 rounded-xl overflow-hidden my-4 flex items-center justify-center cursor-pointer"
      onClick={() => handleNavigate(service.path)}
    >
      <img
        src={service.img}
        alt={service.title}
        className="absolute top-0 left-0 w-full h-full object-cover"
      />
      {/* Linear Gradient */}
      <div
        className="absolute inset-0 bg-gradient-to-r"
        style={{
          backgroundImage: `linear-gradient(to right, ${service.hex}, rgba(0, 0, 0, 0))`,
        }}
      >
        <div className="absolute top-0 left-0 w-full md:w-3/4  h-full flex flex-col justify-center gap-4 p-4">
          <h2 className="text-white text-lg md:text-xl lg:text-2xl font-bold">
            {service.title}
          </h2>
          <p className="text-white text-sm md:text-base">
            {service.description}
          </p>
          <div className="flex items-center">
            <p className="text-white text-sm md:text-base underline">
              {service.button}
            </p>
            <FaArrowRight
              className="ml-2 text-white cursor-pointer"
              size={18}
              onClick={() => handleNavigate(service.path)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
