import React, { useState } from "react";
import { axiosClient } from "../utils/axiosClient";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getShopCustomer } from "../redux/slices/shopsSlice";
import { FaStar } from "react-icons/fa";

const RatingModal = ({ onClose, shopId }) => {
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      const response = await axiosClient.post(`/rate/shopRating/${shopId}`, {
        rating,
      });
      toast.success(response.result);
      dispatch(getShopCustomer(shopId));
    } catch (e) {
      toast.error(e);
    } finally {
      onClose();
    }
  };

  const ratingMessages = {
    1: "Very Bad Experience 😞",
    2: "Not Satisfied 😐",
    3: "It's Okay 😌",
    4: "Good Experience 😊",
    5: "Excellent! Loved it! 😍",
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-md text-center w-full max-w-md mx-4 sm:mx-auto">
        <h2 className="mb-4 text-2xl font-bold text-gray-800">Rate</h2>
        <div className="mb-4">
          <p className="mb-2 text-lg">Please rate your experience:</p>
          <div className="flex justify-center">
            {[1, 2, 3, 4, 5].map((value) => (
              <FaStar
                key={value}
                className={`cursor-pointer text-4xl ${
                  value <= rating ? "text-yellow-500" : "text-gray-300"
                }`}
                onClick={() => setRating(value)}
              />
            ))}
          </div>
          {rating > 0 && (
            <p className="mt-4 text-lg text-blue-900 font-semibold">
              {ratingMessages[rating]}
            </p>
          )}
        </div>
        <div className="flex justify-between space-x-4 mt-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-150 ease-in-out"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RatingModal;
